export const userFormLayout = [
  {
    id: 1,
    label: "Name",
    key: "name",
    type: "text",
    placeholder: "Name",
    containerClass: "col-sm-12 col-md-6 col-lg-6  ",
    required: true,
  },
  {
    id: 2,
    label: "Email",
    key: "email",
    type: "email",
    placeholder: "Email",
    containerClass: "col-sm-12 col-md-6 col-lg-6  ",
  },
  {
    id: 3,
    label: "Role",
    key:"role_name",
    type: "select",
    placeholder: "Select Role",
    options: [
      {
        label: "Admin",
        value: "2",
      },
      {
        label: "Manager",
        value: "3",
      },
      {
        label: "Associate",
        value: "4",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-6",
  },
  {
    id: 4,
    label: "Password",
    key: "password",
    type: "password",
    placeholder: "Password",
    containerClass: "col-sm-12 col-md-6 col-lg-6  ",
  },
];

export const userFormLayoutJap = [
  {
    id: 1,
    label: "名前",
    key: "name",
    type: "text",
    placeholder: "名前",
    containerClass: "col-sm-12 col-md-6 col-lg-6  ",
    required: true,
  },
  {
    id: 2,
    label: "Eメール",
    key: "email",
    type: "email",
    placeholder: "Eメール",
    containerClass: "col-sm-12 col-md-6 col-lg-6  ",
  },
  {
    id: 3,
    label: "役割",
    type: "select",
    placeholder: "Select Role",
    key: "role_id",
    options: [
      {
        label: "Admin",
        value: "1",
      },
      {
        label: "Manager",
        value: "3",
      },
      {
        label: "Associate",
        value: "4",
      },
    ],
    containerClass: "col-sm-12 col-md-4 col-lg-6",
  },
  {
    id: 4,
    label: "パスワード",
    key: "password",
    type: "password",
    placeholder: "パスワード",
    containerClass: "col-sm-12 col-md-6 col-lg-6  ",
  },
];
