import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { apipaths } from "../../services/apiPaths";
import CardContainer from "../Card/cardContainer";
import Heading from "../Heading";
import { meetingListAction } from "./../actions/meetingAction";
import { useDispatch, useSelector } from "react-redux";
import {
  formatDate,
  formatDateList,
  regName,
} from "../../helpers/commonHelpers";
import { clientListAction } from "../actions/clientAction";
import { companyListAction } from "../actions/comanyAction";
import { projectListAction } from "../actions/projectAction";
import { employeesListAction } from "./../actions/employeesListAction";
import "bootstrap/dist/css/bootstrap.css";
import useTranslation from "../customHooks/translations";
import { sendRequest } from "../../services";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function MeetingForm() {
  const [formData, setFormData] = useState({});
  const [preStartDate, setPreStartDate] = useState({ startdate: "", enddate: "" });
  const [date, setDate] = useState("");
  const [postStartDate, setPostStartDate] = useState({ startdate: "", enddate: "" });
  // const [postEndDate, setPostEndDate] = useState("");

  const translation = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(meetingListAction());
    dispatch(clientListAction());
    dispatch(companyListAction());
    dispatch(projectListAction());
    dispatch(employeesListAction());
  }, [dispatch]);

  const onSubmit = async () => {
    if (formData.subject === undefined || !regName.test(formData.subject)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Meeting Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let new_meeting_details = { ...formData };
      const formattedDateRange = `${preStartDate.startdate} to ${preStartDate.enddate}`;
      const formattedPostDateRange = `${postStartDate.startdate} to ${postStartDate.enddate}`;

      if(preStartDate.startdate !== ''){
        new_meeting_details = {
          ...new_meeting_details,
          startdate: date,
          Pre_startdate: formattedDateRange,
          OPtype: "Meeting",
          operation: "add",
          task_type: "keep",
        };
      }
      else if(postStartDate.startdate !== '' && preStartDate.startdate !== ''){
        new_meeting_details = {
          ...new_meeting_details,
          startdate: date,
          Pre_startdate: formattedDateRange,
          Post_startdate: formattedPostDateRange,
          OPtype: "Meeting",
          operation: "add",
          task_type: "keep",
        };
      }else{
        new_meeting_details = {
          ...new_meeting_details,
          startdate: date,
          OPtype: "Meeting",
          operation: "add",
          task_type: "keep",
        };
      }
      const {
        success,
        data: {  },
      } = await sendRequest(apipaths.addMeeting, new_meeting_details);
      if (success) {
        dispatch(meetingListAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: 'Metting add successfully!',
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(-1);
      }
    }
  };

  const onCancel = () => {
    navigate(-1);
  };
  const fillClient = clients.filter((d) => {
    return d.company_id === parseFloat(formData.company_id);
  });

  const fillProject = projects.filter((d) => {
    return d.client_id === parseFloat(formData.client_id);
  });

  const handleEvent = (event, picker) => {
    const time = "12:00"; // Define the time to append
    const startdate = formatDateList(event[0]) + " " + time;
    const enddate = formatDateList(event[1]) + " " + time;
  
    setPreStartDate({
      startdate,
      enddate,
    });
  };

  const handleEventPost = (event, picker) => {
    const startdate = formatDate(event[0]);
    const enddate = formatDate(event[1]);
  
    setPostStartDate({
      startdate,
      enddate,
    });
  };

  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };
  const preDateChange = (date) => {
    handleEvent(date);
  };
  const postDateChange = (date) => {
    handleEventPost(date);
  };

  const dateChange = (date) => {
    setDate(formatDateList(date));
  };

  const dateFormatList = ["YYYY/MM/DD", "YYYY/MM/DD"];

  return (
    <>
      <div className="main-container">
        <Heading title={translation.add_meeting} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
          <div className="content-container">
            <div className=" p-3 ">
              <Tabs className="row">
                <Tabs.TabPane
                  tab={translation.meetings}
                  key="item-1"
                  className="row"
                >
                  <div className="col-12 col-md-6 col-lg-9">
                    <label for="Meetings" className="form-label">
                      {translation.meetings}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      aria-describedby="emailHelp"
                      placeholder={translation.meetings}
                      name="subject"
                      key="subject"
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <label for="Date" className="form-label">
                      {translation.date}
                    </label>

                    <DatePicker
                      className="w-100"
                      name="startdate"
                      key="startdate"
                      placeholder={translation.select_date}
                      onChange={(date) => dateChange(date)}
                      style={{ borderRadius: "0.375rem" }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mt-3">
                    <label for="Start Time" className="form-label">
                      {translation.start_time}
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      id="starttime"
                      aria-describedby="emailHelp"
                      placeholder={translation.start_time}
                      name="starttime"
                      key="starttime"
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mt-3">
                    <label for="End Time" className="form-label">
                      {translation.end_time}
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      id="endtime"
                      aria-describedby="emailHelp"
                      placeholder={translation.end_time}
                      name="endtime"
                      key="endtime"
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mt-3">
                    <label for="Venue" className="form-label">
                      {translation.venue}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="venue"
                      aria-describedby="emailHelp"
                      placeholder={translation.venue}
                      name="venue"
                      key="venue"
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mt-3">
                    <label for="Company" className="form-label">
                      {translation.company}
                    </label>
                    <select
                      className="form-select"
                      aria-label="Select Company"
                      key="company_id"
                      onChange={onInputChange}
                      name="company_id"
                    >
                      {" "}
                      <option selected>{translation.select_company}</option>
                      {companies.map((d, i) => (
                        <option key={d.company_id} value={d.company_id}>
                          {d.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mt-3">
                    <label for="Client" className="form-label">
                      {translation.client}
                    </label>
                    <select
                      className="form-select"
                      aria-label=" Select Client"
                      key="client_id"
                      onChange={onInputChange}
                      name="client_id"
                    >
                      {" "}
                      <option selected>{translation.select_client}</option>
                      {fillClient.map((c, i) => (
                        <option key={c.client_id} value={c.client_id}>
                          {c.first_name + "" + c.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mt-3">
                    <label for="Project" className="form-label">
                      {translation.project}
                    </label>
                    <select
                      className="form-select"
                      aria-label=" select Project"
                      id="project_id"
                      key="project_id"
                      onChange={onInputChange}
                      name="project_id"
                    >
                      <option selected>{translation.select_project}</option>
                      {fillProject.map((p, i) => (
                        <option key={p.project_id} value={p.project_id}>
                          {p.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-2 col-lg-3 mt-3">
                    <label for="Incharge Employee" className="form-label">
                      {translation.incharge_employee}
                    </label>
                    <select
                      className="form-select"
                      aria-label="Select Incharge Employee"
                      key="employee_id"
                      onChange={onInputChange}
                      id="employee_id"
                      name="employee_id"
                    >
                      <option selected>
                        {translation.select_incharge_employee}
                      </option>
                      {employees.map((e, i) => (
                        <option key={e.employee_id} value={e.employee_id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-6 col-lg-9 mt-3">
                    <label for="Attendees" className="form-label">
                      {translation.attendees}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="attendees"
                      aria-describedby="emailHelp"
                      placeholder={translation.attendees}
                      name="attendees"
                      key="attendees"
                      onChange={onInputChange}
                    />
                  </div>

                  <div className="col-12  mt-3">
                    <label for="Description" className="form-label">
                      {translation.description}
                    </label>
                    <textarea
                      className="form-control"
                      id="desc"
                      placeholder={translation.description}
                      name="desc"
                      key="desc"
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 mt-3">
                    <label for="Remarks" className="form-label">
                      {translation.remarks}
                    </label>
                    <textarea
                      className="form-control"
                      placeholder={translation.remarks}
                      id="remarks"
                      key="remarks"
                      onChange={onInputChange}
                      name="remarks"
                    ></textarea>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 mt-3">
                    <label for="Keypoints" className="form-label">
                      {translation.keypoints}
                    </label>
                    <textarea
                      className="form-control"
                      id="keypoints"
                      key="keypoints"
                      name="keypoints"
                      placeholder={translation.keypoints}
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={translation.pre_task}
                  key="item-2"
                  className="row"
                >
                  <div className="col-12 col-md-6 col-lg-8">
                    <label for="Subject" className="form-label">
                      {translation.subject}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Pre_subject"
                      aria-describedby="emailHelp"
                      placeholder={translation.subject}
                      name="Pre_subject"
                      key="Pre_subject"
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <label for="Due Date" className="form-label">
                      {translation.startDate_to_endDate}
                    </label>
                    <RangePicker
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => preDateChange(date)}
                      placeholder={[translation.start__end_date]}
                      format={dateFormatList}
                      className="form-control border-none rounded-lg"
                    />
                  </div>
                  <div className="mb-2 col-lg-3 mt-3">
                    <label for="Priority" className="form-label">
                      {translation.priority}
                    </label>
                    <select
                      className="form-select"
                      aria-label="Select Priority"
                      key="Pre_priority"
                      onChange={onInputChange}
                      id="Pre_priority"
                      name="Pre_priority"
                    >
                      <option selected>{translation.select_priority}</option>
                      <option value="Urgent">Urgent</option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                  </div>
                  <div className="mb-2 col-lg-3 mt-3">
                    <label for="Status" className="form-label">
                      {translation.status}
                    </label>
                    <select
                      className="form-select"
                      aria-label=" Select Status"
                      key="Pre_status"
                      onChange={onInputChange}
                      name="Pre_status"
                    >
                      {" "}
                      <option selected>{translation.select_status}</option>
                      <option value="Pending">Pending</option>
                      <option value="Started">Started</option>
                      <option value="Inprogress">Inprogress</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                  <div className="mb-2 col-lg-3 mt-3">
                    <label for="Task Assigned To" className="form-label">
                      {translation.task_assigned_to}
                    </label>
                    <select
                      className="form-select"
                      aria-label=" Select Task Assigned To"
                      key="Pre_task_assign_to"
                      onChange={onInputChange}
                      name="Pre_task_assign_to"
                    >
                      {" "}
                      <option selected>{translation.task_assigned_to}</option>
                      {employees.map((e, i) => (
                        <option key={e.employee_id} value={e.employee_id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-2 col-lg-3 mt-3">
                    <label for="Employee" className="form-label">
                      {translation.employee}
                    </label>
                    <select
                      className="form-select"
                      aria-label=" Select Employee"
                      key="Pre_employee_id"
                      onChange={onInputChange}
                      name="Pre_employee_id"
                    >
                      {" "}
                      <option selected>{translation.employee}</option>
                      {employees.map((e, i) => (
                        <option key={e.employee_id} value={e.employee_id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12  mt-2">
                    <label for="Description" className="form-label">
                      {translation.description}
                    </label>
                    <textarea
                      className="form-control"
                      id="Pre_desc"
                      rows="3"
                      placeholder={translation.description}
                      name="Pre_desc"
                      key="Pre_desc"
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                  <div className="col-12  mt-2">
                    <label for="Remarks" className="form-label">
                      {translation.remarks}
                    </label>
                    <textarea
                      className="form-control"
                      id="Pre_remarks"
                      rows="3"
                      placeholder={translation.remarks}
                      name="Pre_remarks"
                      key="Pre_remarks"
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={translation.post_task}
                  key="item-3"
                  className="row"
                >
                  <div className="col-12 col-md-6 col-lg-8">
                    <label for="Subject" className="form-label">
                      {translation.subject}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Post_subject"
                      aria-describedby="emailHelp"
                      placeholder={translation.subject}
                      name="Post_subject"
                      key="Post_subject"
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <label for="Due Date" className="form-label">
                      {translation.startDate_to_endDate}
                    </label>
                    <RangePicker
                      onChange={(date) => postDateChange(date)}
                      placeholder={[translation.start__end_date]}
                      className="form-control border-none rounded-lg"
                    />
                  </div>
                  <div className="mb-2 col-lg-3 mt-3">
                    <label for="Priority" className="form-label">
                      {translation.priority}
                    </label>
                    <select
                      className="form-select"
                      aria-label="Select Priority"
                      key="Post_priority"
                      onChange={onInputChange}
                      id="Post_priority"
                      name="Post_priority"
                    >
                      <option selected>{translation.select_priority}</option>
                      <option value="Urgent">Urgent</option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                  </div>
                  <div className="mb-2 col-lg-3 mt-3">
                    <label for="Status" className="form-label">
                      {translation.status}
                    </label>
                    <select
                      className="form-select"
                      aria-label=" Select Status"
                      key="Post_status"
                      onChange={onInputChange}
                      name="Post_status"
                    >
                      {" "}
                      <option selected>{translation.select_status}</option>
                      <option value="Pending">Pending</option>
                      <option value="Started">Started</option>
                      <option value="Inprogress">Inprogress</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                  <div className="mb-2 col-lg-3 mt-3">
                    <label for="Task Assigned To" className="form-label">
                      {translation.task_assigned_to}
                    </label>
                    <select
                      className="form-select"
                      aria-label=" Select Task Assigned To"
                      key="Post_task_assign_to"
                      onChange={onInputChange}
                      name="Post_task_assign_to"
                    >
                      {" "}
                      <option selected>{translation.task_assigned_to}</option>
                      {employees.map((e, i) => (
                        <option key={e.employee_id} value={e.employee_id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-2 col-lg-3 mt-3">
                    <label for="Employee" className="form-label">
                      {translation.employee}
                    </label>
                    <select
                      className="form-select"
                      aria-label=" Select Employee"
                      key="Post_employee_id"
                      onChange={onInputChange}
                      name="Post_employee_id"
                    >
                      {" "}
                      <option selected>{translation.employee}</option>
                      {employees.map((e, i) => (
                        <option key={e.employee_id} value={e.employee_id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12  mt-2">
                    <label for="Description" className="form-label">
                      {translation.description}
                    </label>
                    <textarea
                      className="form-control"
                      id="Post_desc"
                      rows="3"
                      placeholder={translation.description}
                      name="Post_desc"
                      key="Post_desc"
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                  <div className="col-12  mt-2">
                    <label for="Remarks" className="form-label">
                      {translation.remarks}
                    </label>
                    <textarea
                      className="form-control"
                      id="Post_remarks"
                      rows="3"
                      placeholder={translation.remarks}
                      name="Post_remarks"
                      key="Post_remarks"
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                </Tabs.TabPane>
              </Tabs>
              <div className="col-lg-12 mb-2 d-flex justify-content-end mt-3">
                <button
                  type="button"
                  className="btn btn-danger px-3 py-2 me-2"
                  onClick={() => onCancel()}
                >
                  {translation.cancel}
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-3 py-2"
                  onClick={() => onSubmit()}
                >
                  {translation.add}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MeetingForm;
