import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { addActionColumn } from "../../helpers/companyHelpers";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import useTranslation from "../customHooks/translations";
import TableCard from "../TableCard";
import "./style.css";

const CounterParty = ({ setModal, projectId, companyId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderdata, setOrderData] = useState([]);
  const [formData, setFormData] = useState({});
  const [editData, setEditData] = useState({});
  const translation = useTranslation();
  const location = useLocation();

  const mainLocation = location.pathname.slice(1, -4);

  const { companies } = useSelector((state) => state.company);
  const { projects } = useSelector((state) => state.project);

  const name = companies.filter((d) => {
    return d.company_id === parseInt(companyId);
  });

  const nameProject = projects.filter((d) => {
    return d.project_id === parseInt(projectId);
  });

  const selectedProjectName =
    mainLocation === "edit-company" ? name[0]?.name : nameProject[0]?.name;

  const counterPartyColumn = [
    {
      title: "ID",
      dataIndex: "counter_party_id",
      sorter: (a, b) => a.counter_party_id - b.counter_party_id,
    },
    {
      title: "Counter Party",
      dataIndex: "company",
      sorter: (a, b) => a?.company?.name.localeCompare(b?.company?.name),
      render: (counter_party_id, row) => {
        return (
          <label
            onClick={() => {
              editHandler(row);
            }}
          >
            {row?.company?.name}
          </label>
        );
      },
    },
    {
      title: "Industry",
      dataIndex: "industry",
      sorter: (a, b) => a?.industry.localeCompare(b?.industry),
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: (a, b) => a?.location.localeCompare(b?.location),
    },
    {
      title: "Partnership Mode",
      dataIndex: "partnership_mode",
      sorter: (a, b) => a?.partnership_mode.localeCompare(b?.partnership_mode),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      sorter: (a, b) => a?.currency.localeCompare(b?.currency),
    },
  ];
  const counterPartyColumnHome = [
    {
      title: "ID",
      dataIndex: "counter_party_id",
      sorter: (a, b) => a.counter_party_id - b.counter_party_id,
    },
    {
      title: "相手方",
      dataIndex: "company",
      sorter: (a, b) => a?.company?.name.localeCompare(b?.company?.name),
      render: (counter_party_id, row) => {
        return (
          <label
            onClick={() => {
              editHandler(row);
            }}
          >
            {row?.company?.name}
          </label>
        );
      },
    },
    {
      title: "業界",
      dataIndex: "industry",
      sorter: (a, b) => a?.industry.localeCompare(b?.industry),
    },
    {
      title: "位置",
      dataIndex: "location",
      sorter: (a, b) => a?.location.localeCompare(b?.location),
    },
    {
      title: "パートナーシップモード",
      dataIndex: "partnership_mode",
      sorter: (a, b) => a?.partnership_mode.localeCompare(b?.partnership_mode),
    },
    {
      title: "通貨",
      dataIndex: "currency",
      sorter: (a, b) => a?.currency.localeCompare(b?.currency),
    },
  ];

  // useEffect(() => {
  //   if (companies) {
  //     listingCommonDefaulter([companies], counterFormLayout, ["company_id"]);
  //   }
  // }, [companies]);

  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFormData("");
    setEditData("");
  };

  const mainhandler = () => {
    if (editData.counter_party_id) {
      updateTaskHandler();
    } else {
      submitHandler();
    }
  };
  const updateTaskHandler = async () => {
    const allData = {
      id: editData.counter_party_id,
      industry: formData.industry,
      location: formData.location,
      partnership_mode: formData.partnership_mode,
      expected_amount: formData.expected_amount,
      source_of_introduction: formData.source_of_introduction,
      last_action: formData.last_action,
      counter_party_company: formData.counter_party_company,
      currency: formData.currency,
      confidence_level: formData.confidence_level,
      stage_of_proceeding: formData.stage_of_proceeding,
      is_introduced: formData.is_introduced,
    };
    const { success } = await sendRequest(apipaths.updateCounterparty, allData);
    if (success) {
      OrderList();
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Counter Party Update Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      handleCancel();
    }
  };

  const submitHandler = async () => {
    if (formData.counter_party_company === undefined) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Select Counter Party",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let new_task_details = {
        ...formData,
        enable: "1",
      };
      new_task_details = {
        ...new_task_details,
      };
      const {
        success,
        data: { message },
      } = await sendRequest(apipaths.addCounterParty, new_task_details);
      if (success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      OrderList();
      setIsModalVisible(false);
    }
  };
  const OrderList = async () => {
    const { error, data } = await sendRequest(apipaths.counterpartys);
    if (error) {
      let message = error.error ? error.error : error.message;
      return message
    } else {
      setOrderData(data?.party);
    }
  };

  const editHandler = (allData) => {
    setEditData(allData);
    setIsModalVisible(true);
  };

  const deleteTaskHandler = (counterData) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data } = await sendRequest(apipaths.deletecounterpartys, {
        delete_id: counterData.counter_party_id,
      });
      const { success, message } = data[0].original;
      if (success === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      OrderList();
    });
  };

  useEffect(() => {
    OrderList();
  }, []);

  return (
    <>
      <TableCard
        title={translation.counterparty}
        button={{
          title: translation.add_counter_party,
          onClick: () => showModal(),
        }}
        columns={addActionColumn(
          {},
          counterPartyColumn,
          (allData) => editHandler(allData),
          (data) => deleteTaskHandler(data),
          translation._language
        )}
        jaCol={addActionColumn(
          {},
          counterPartyColumnHome,
          (allData) => editHandler(allData),
          (data) => deleteTaskHandler(data),
          translation._language
        )}
        data={orderdata}
      />

      <Modal
        destroyOnClose
        width={900}
        centered
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={false}
        maskClosable={false}
        className="model"
      >
        <div
          className="line px-3 d-flex justify-content-between align-items-center"
          style={{ clear: "both", borderBottom: "1px solid #f0f0f0" }}
        >
          <h5>{translation.counterparty}</h5>
          <h5 className="text-success pe-5">{selectedProjectName}</h5>
        </div>
        <form>
          <div className="w-100 p-2 row mt-3 px-4">
            <div className="mb-2 col-lg-3  mt-2">
              <label for="priority" className="form-label">
                {translation.counterparty}
                <span className="text-danger">*</span>
              </label>
              <select
                className="form-select"
                aria-label=" Select Counter Party"
                name="counter_party_company"
                key="company_id"
                type="select"
                require={true}
                defaultValue={editData.counter_party_company}
                onChange={onInputChange}
              >
                <option selected>{translation.select_counter_party}</option>
                {companies.map((d, i) => (
                  <option key={d.company_id} value={d.company_id}>
                    {d.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-6 col-lg-3  mt-2">
              <label for="Start Time" className="form-label">
                {translation.industry}
              </label>
              <input
                type="text"
                className="form-control"
                id="industry"
                aria-describedby="emailHelp"
                placeholder={translation.industry}
                name="industry"
                key="industry"
                onChange={onInputChange}
                defaultValue={editData.industry}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-2">
              <label for="location" className="form-label">
                {translation.location}
              </label>
              <input
                type="text"
                className="form-control"
                id="location"
                aria-describedby="emailHelp"
                placeholder={translation.location}
                defaultValue={editData.location}
                name="location"
                key="location"
                onChange={onInputChange}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-2">
              <label for="partnership_mode" className="form-label">
                {translation.partnership_mode}
              </label>
              <input
                type="text"
                className="form-control"
                id="partnership_mode"
                aria-describedby="emailHelp"
                placeholder={translation.partnership_mode}
                name="partnership_mode"
                key="partnership_mode"
                defaultValue={editData.partnership_mode}
                onChange={onInputChange}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-2">
              <label for="expected_amount" className="form-label">
                {translation.expected_amount}
              </label>
              <input
                type="text"
                className="form-control"
                id="expected_amount"
                aria-describedby="emailHelp"
                placeholder={translation.expected_amount}
                defaultValue={editData.partnership_mode}
                name="expected_amount"
                key="expected_amount"
                onChange={onInputChange}
              />
            </div>
            <div className="mb-2 col-lg-3  mt-2">
              <label for="currency" className="form-label">
                {translation.currency}
              </label>
              <select
                className="form-select"
                aria-label=" Select currency"
                key="currency"
                onChange={onInputChange}
                defaultValue={editData.currency}
                name="currency"
              >
                <option selected>{translation.select_currency}</option>
                <option value="INR">INR</option>
                <option value="JYP">JYP</option>
                <option value="USD">USD</option>
                <option value="CAD">CAD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                <option value="AUD">AUD</option>
              </select>
            </div>
            <div className="mb-2 col-lg-3  mt-2">
              <label for="confidence_level" className="form-label">
                {translation.confidence_level}
              </label>
              <select
                className="form-select"
                aria-label=" Select confidence_level"
                key="confidence_level"
                defaultValue={editData.confidence_level}
                onChange={onInputChange}
                name="confidence_level"
              >
                <option selected>{translation.select_confidence_level}</option>
                <option value="Very High">Very High</option>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-2">
              <label for="source_of_introduction" className="form-label">
                {translation.source_of_introduction}
              </label>
              <input
                type="text"
                className="form-control"
                id="source_of_introduction"
                aria-describedby="emailHelp"
                placeholder={translation.source_of_introduction}
                name="source_of_introduction"
                key="source_of_introduction"
                onChange={onInputChange}
                defaultValue={editData.source_of_introduction}
              />
            </div>
            <div className="mb-2 col-lg-3  mt-2">
              <label for="stage_of_proceeding" className="form-label">
                {translation.stage_of_proceeding}
              </label>
              <select
                className="form-select"
                aria-label=" Select stage_of_proceeding"
                key="stage_of_proceeding"
                onChange={onInputChange}
                defaultValue={editData.stage_of_proceeding}
                name="stage_of_proceeding"
              >
                <option selected>
                  {translation.select_stage_of_proceeding}
                </option>
                <option value="Candidate List">Candidate List</option>
                <option value="Introduction">Introduction</option>
                <option value="NDA Processing">NDA Processing</option>
                <option value="Documents Sharing">Documents Sharing</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advance">Advance</option>
                <option value="Negotiation">Negotiation</option>
                <option value="Near Closing">Near Closing</option>
                <option value="Closed">Closed</option>
                <option value="Terminated">Terminated</option>
                <option value="Suspended">Suspended</option>
                <option value="Post Closing">Post Closing</option>
              </select>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-2">
              <label for="last_action" className="form-label">
                {translation.last_action}
              </label>
              <input
                type="text"
                className="form-control"
                id="last_action"
                aria-describedby="emailHelp"
                placeholder={translation.last_action}
                name="last_action"
                key="last_action"
                onChange={onInputChange}
                defaultValue={editData.last_action}
              />
            </div>
            <div className="mb-2 col-lg-3  mt-2">
              <label for="is_introduced" className="form-label">
                {translation.is_introduced}
              </label>
              <select
                className="form-select"
                aria-label=" Select is_introduced"
                key="is_introduced"
                onChange={onInputChange}
                defaultValue={editData.is_introduced}
                name="is_introduced"
              >
                <option selected>{translation.select_is_introduced}</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="col-lg-12 mb-2 d-flex justify-content-end ">
              <button
                type="button"
                className="btn btn-danger px-3 py-2 me-2"
                onClick={() => handleCancel()}
              >
                {translation.cancel}
              </button>
              <button
                type="button"
                className="btn btn-primary px-3 py-2"
                onClick={() => mainhandler()}
              >
                {!editData.counter_party_id
                  ? translation.add
                  : translation.update}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default CounterParty;
