import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteHandler } from "../../apiParsing/data";
import {
  formatDateList,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import { addActionColumn } from "../../helpers/companyHelpers";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import TableCard from "../TableCard";
import { taskListAction } from "./../actions/taskListAction";
import TaskCompanyForm from "./TaskCompanyForm";
import useTranslation from "../customHooks/translations";
import moment from "moment";

const TaskDataTable = ({ setModal, companyId, projectId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const mainLocation = location.pathname.slice(1, -4);
  const translation = useTranslation();

  const [navId, setNavId] = useState("");
  const [taskList, setTaskList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState({});

  const { tasks } = useSelector((state) => state.task);
  const { companies } = useSelector((state) => state.company);

  const locationCheck = () => {
    if (mainLocation === "edit-company") {
      setNavId(companyId);
    } else {
      setNavId(projectId);
    }
  };
  useEffect(() => {
    locationCheck();
  }, []);

  const name = companies.filter((d) => {
    return d.company_id == parseInt(navId);
  });

  const selectedCompanyName = name[0]?.name;

  useEffect(() => {
    if (tasks && companyId)
      setTaskList(tasks.filter((m) => m.company_id == parseInt(companyId)));
    else if (tasks && projectId)
      setTaskList(tasks.filter((m) => m.project_id == parseInt(projectId)));
  }, [tasks]);

  const taskColumns = [
    {
      title: "ID",
      dataIndex: "task_id",
      sorter: (a, b) => a.task_id - b.task_id,
      render: (id) => {
        return id;
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sorter: (a, b) => a?.subject?.localeCompare(b?.subject),
      render: (subject, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => editHandler(data)}
            >
              {subject}
            </label>
          </>
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      sorter: (a, b) => a?.priority?.localeCompare(b?.priority),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Start To End Date",
      dataIndex: "startdate",
      sorter: (a, b) =>
        moment(formatDateList(a.startdate)).unix() -
        moment(formatDateList(b.startdate)).unix(),
      render: (startdate, data) => {
        return (
          formatDateList(startdate) +
          " " +
          "to" +
          " " +
          formatDateList(data.enddate)
        );
      },
    },
    {
      title: "Employee",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id - b.employee_id,
      render: (employee_id, row) => {
        const employee = row["employee"];
        if (employee) return employee.first_name + " " + employee.last_name;
        else return "";
      },
    },
  ];

  const taskColumnsHome = [
    {
      title: "ID",
      dataIndex: "task_id",
      sorter: (a, b) => a.task_id - b.task_id,
      render: (id) => {
        return id;
      },
    },
    {
      title: "主題",
      dataIndex: "subject",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (d, data) => {
        return (
          <>
            <label
              style={{ color: "#1890ff" }}
              onClick={() => editHandler(data)}
            >
              {data.subject}
            </label>
          </>
        );
      },
    },
    {
      title: "優先順位",
      dataIndex: "priority",
      sorter: (a, b) => a.priority.localeCompare(b.priority),
    },
    {
      title: "状態",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "開始日から終了日まで",
      dataIndex: "startdate",
      sorter: (a, b) =>
        moment(formatDateList(a.startdate)).unix() -
        moment(formatDateList(b.startdate)).unix(),
      render: (startdate, data) => {
        return (
          formatDateList(startdate) +
          " " +
          "to" +
          " " +
          formatDateList(data.enddate)
        );
      },
    },
    {
      title: "社員",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id - b.employee_id,
      render: (employee_id, row) => {
        const employee = row["employee"];
        if (employee) return employee.first_name + " " + employee.last_name;
        else return "";
      },
    },
  ];

  const updateTaskHandler = async (task) => {
    task["id"] = task.task_id;
    delete task.project_id;
    delete task.enable;
    delete task.company;
    delete task.client;
    delete task.created_at;
    delete task.updated_at;
    delete task.user_id;
    delete task.edate;
    delete task.sdate;
    delete task.status;
    delete task.employee;
    delete task.company_project_profile_id;

    task["operation"] = "update";
    task["task_type"] = "update";
    task["OPtype"] = "Task";

    const {
      data: { message },
      success,
    } = await sendRequest(apipaths.addTask, task);
    if (success) {
      dispatch(taskListAction());
      setModal(modalInitialValue);
      Swal.fire({
        position: "center",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    dispatch(taskListAction());
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const editHandler = (allData) => {
    setEditData(allData);
    setIsModalVisible(true);
  };

  return (
    <>
      <TableCard
        button={{
          title: translation.add_task,
          onClick: () => showModal(),
        }}
        columns={addActionColumn(
          {},
          taskColumns,
          (allData) => editHandler(allData),
          (data) =>
            deleteHandler(
              apipaths.deleteTask,
              data?.["task_id"],
              setModal,
              dispatch,
              taskListAction
            ),
          translation._language
        )}
        jaCol={addActionColumn(
          { type: `dropdown`, name: `tasksHome-dropdown` },
          taskColumnsHome,
          (allData) => editHandler(allData),
          (data) =>
            deleteHandler(
              apipaths.deleteTask,
              data?.["task_id"],
              setModal,
              dispatch,
              taskListAction
            ),
          translation._language
        )}
        data={taskList}
        title={translation.task}
      />
      <TaskCompanyForm
        navId={navId}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};

export default TaskDataTable;
