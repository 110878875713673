export const ContactsForm = [
  {
    id: 1,
    type: "file",
    key: "contacts",
    label: "Import Contacts (XLSX)",
    required: true,
    containerClass: "col-12 col-md-6 col-lg-6",
  },
];

export const ContactsFormJap = [
  {
    id: 1,
    type: "file",
    key: "contacts",
    label: "連絡先をインポートする (XLSX)",
    containerClass: "col-12 col-md-6 col-lg-6",
  },
];
