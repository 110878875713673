import { CalendarFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { BiMenu } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  formModalController,
  getUserRole,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import { apipaths } from "../../services/apiPaths";
import { addUserDetailsAction } from "../actions/userActions";
import "./style.css";
import { sendRequest } from "../../services";
import { memoListAction } from "../actions/memoListAction";
import Swal from "sweetalert2";
import TableCard from "../TableCard";
import { addActionColumn } from "../../helpers/companyHelpers";
import {
  quickMemosForm,
  quickMemosFormJap,
} from "../../Data/FormsLayout/quickMemosForm";
import ModalContainer from "../Modal";
import { deleteHandler, updateHandler } from "../../apiParsing/data";
import LanguageHandler from "../LanguageHandler";
import useTranslation from "../customHooks/translations";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  quickMemosColumns,
  quickMemosColumnsHome,
} from "../../Data/Columns/quickMemosColumn";
import { IoMdSettings } from "react-icons/io";
import LanguageChange from "../LanguageChange";

function Navbar({ setSidebarToggle }) {
  const [formData, setFormData] = useState({
    memo: "",
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modal, setModal] = useState(modalInitialValue);
  const { memos } = useSelector((state) => state.memos);

  const translation = useTranslation();

  useEffect(() => {
    dispatch(memoListAction());
    dispatch(addUserDetailsAction());
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.user);

  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (formData.memo.trim() === "") {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Memo Required",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let new_memo_details = { ...formData };
      new_memo_details = {
        ...new_memo_details,
        operation: "add",
        memo_type: "keep",
      };

      const {
        success,
        data: { message },
      } = await sendRequest(apipaths.addMemo, new_memo_details);
      if (success) {
        dispatch(memoListAction());
        setFormData({
          memo: "",
        });
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Memo Added Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Add Memo
    </Tooltip>
  );
  const renderTooltipCalender = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Calender
    </Tooltip>
  );
  useEffect(() => {
    let focus = document.querySelector(".input-group");

    focus.addEventListener("mouseover", () => {
      focus.classList.toggle("focus");
    });
    focus.addEventListener("mouseout", () => {
      focus.classList.toggle("focus");
    });
  }, []);

  return (
    <div
      className="navbar-container d-flex align-items-center justify-content-between px-5"
      onClick={() => (isDropdownOpen ? setIsDropdownOpen(false) : "")}
    >
      <div className="d-flex align-items-center">
        <ModalContainer
          isOpen={modal.open}
          title={modal.title}
          closeIcon={() => setModal(modalInitialValue)}
          content={modal.content}
          footer={false}
        />
        <BiMenu
          size={25}
          cursor="pointer"
          className="me-2 nav-menu-icon"
          fill="#fff"
          onClick={() => setSidebarToggle((sidebarToggle) => !sidebarToggle)}
        />
        <form className="navbar-left navbar-form nav-search m-2">
          <div className="input-group">
            <input
              type="text"
              autoComplete="off"
              placeholder={translation.add_memo}
              className="memo-input"
              name="memo"
              value={formData?.memo}
              onChange={onInputChange}
              required="required"
            />
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 100 }}
              overlay={renderTooltip}
            >
              <button
                type="submit"
                className="btn btn-search pr-1 trigger-btn"
                onClick={(e) => submitHandler(e)}
                style={{
                  fontSize: "18px",
                  marginBottom: "2px",
                }}
              >
                <PlusOutlined />
              </button>
            </OverlayTrigger>
          </div>
        </form>
        {/* for show all memos  */}
        <Button
          type="primary"
          className="ms-2 view-memo-btn"
          onClick={showModal}
        >
          {translation.view_all_memos}
        </Button>

        {/* <LanguageChange /> */}
        {/* globly locatlization  */}
        <Modal
          // title={translation.memo}
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={handleCancel}>
              Close
            </Button>,
          ]}
          className="modalStyle"
        >
          <TableCard
            title={translation.memo}
            columns={addActionColumn(
              {},
              quickMemosColumns,
              (data) =>
                formModalController(
                  setModal,
                  quickMemosForm,
                  quickMemosFormJap,
                  `${translation.edit_quick_memos}`,
                  `edit-quick-memo-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addMemo,
                      data,
                      "memo_id",
                      setModal,
                      dispatch,
                      memoListAction
                    )
                ),
              (data) =>
                deleteHandler(
                  apipaths.deleteMemo,
                  data?.["memo_id"],
                  setModal,
                  dispatch,
                  memoListAction
                ),
              translation._language
            )}
            jaCol={addActionColumn(
              {},
              quickMemosColumnsHome,
              (data) =>
                formModalController(
                  setModal,
                  quickMemosForm,
                  quickMemosFormJap,
                  `${translation.edit_quick_memos}`,
                  `edit-quick-memo-form`,
                  data,
                  (data) =>
                    updateHandler(
                      apipaths.addMemo,
                      data,
                      "memo_id",
                      setModal,
                      dispatch,
                      memoListAction
                    )
                ),
              (data) =>
                deleteHandler(
                  apipaths.deleteMemo,
                  data?.["memo_id"],
                  setModal,
                  dispatch,
                  memoListAction
                ),
              translation._language
            )}
            data={memos}
            rowPerPage={5}
          />
        </Modal>
      </div>

      <div className="d-flex align-items-center justify-content-center profile">
        <LanguageHandler />

        <div
          onClick={() => navigate("/calendar")}
          style={{
            color: "white",
            marginRight: "15px",
          }}
        >
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 100 }}
            overlay={renderTooltipCalender}
            data="dev"
          >
            <CalendarFilled style={{ fontSize: "25px" }} />
          </OverlayTrigger>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <p className="mb-0 text-white me-3">{userDetails.name}</p>
          <div className="dropdown">
            <a
              className=" profile-pic"
              data-bs-toggle="dropdown"
              href="#"
              aria-expanded="true"
            >
              <div className="avatar-sm">
                {/* <img
                src={
                  userDetails.profile_image
                    ? userDetails.profile_image
                    : "./images/itaims.jpg"
                }
                alt="..."
                className="avatar-img rounded-circle"
              /> */}
                <img
                  src="https://jaimdms.com/img/images.png"
                  alt="image profile"
                  className="avatar-img rounded-circle"
                />
              </div>
            </a>
            <ul className="dropdown-menu dropdown-user open-dropdown animated fadeIn">
              <div
                className="scroll-wrapper dropdown-user-scroll p-1"
                style={{ position: "relative" }}
              >
                <div
                  className="dropdown-user-scroll p-1 scroll-content"
                  style={{
                    height: "104.484px",
                    marginBottom: "0px",
                    marginRight: "0px",
                    maxHeight: "none",
                  }}
                >
                  <li>
                    <div className="user-box justify-content-around">
                      <div className="avatar-lg">
                        {/* <img
                        src={
                          userDetails.profile_image
                            ? userDetails.profile_image
                            : "./images/itaims.jpg"
                        }
                        alt="image profile"
                        className="avatar-img rounded"
                      /> */}
                        <img
                          src="https://jaimdms.com/img/images.png"
                          alt="image profile"
                          className="avatar-img rounded"
                        />
                        <Button
                          className="btn mt-2 mx-0 px-1 logout-btn"
                          type="primary"
                          size={"middle"}
                          onClick={() => {
                            dispatch({ type: "LOG_OUT", payload: undefined });
                            localStorage.removeItem("auth-token");
                            navigate("/");
                          }}
                        >
                          {translation.logout}
                        </Button>
                      </div>
                      <div className="u-text">
                        <p
                          className="text-muted mb-0"
                          style={{ fontWeight: "600" }}
                        >
                          {translation.welcome} !
                        </p>
                        <p
                          className="text-muted mb-0"
                          style={{ fontWeight: "600" }}
                        >
                          {userDetails.name}
                        </p>
                        <p className="text-muted mb-0">
                          {" "}
                          {translation.username} : {""}
                          <span className="text-capitalize">
                            {userDetails.username}
                          </span>
                        </p>
                        <p className="text-muted mb-0">
                          {translation.role}:{" "}
                          <span className="text-capitalize">
                            {userDetails && getUserRole(userDetails.role_id)}
                          </span>
                        </p>
                      </div>
                      <div className="setting-icon">
                        <Link to="/userProfile">
                          <IoMdSettings />
                          {/* <FaFan /> */}
                        </Link>
                      </div>
                    </div>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Navbar;
