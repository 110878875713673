import { Modal } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { formatDateList, regName } from "../../helpers/commonHelpers";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import useTranslation from "../customHooks/translations";
import { taskListAction } from "./../actions/taskListAction";
import { useLocation, useNavigate } from "react-router-dom";
import { projectListAction } from "../actions/projectAction";
import "rsuite/dist/rsuite.css";
import "./style.css";
import { DatePicker, Space } from "antd";
import * as moment from "moment";

const { RangePicker } = DatePicker;

const TaskCompanyForm = (props) => {
  const { navId, isModalVisible, setIsModalVisible, editData, setEditData } =
    props;

  const [formData, setFormData] = useState({});
  const [date, setDate] = useState({ startdate: "", enddate: "" });
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const translation = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const mainLocation = location.pathname.slice(1, -4);

  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);

  const name = companies.filter((d) => {
    return d.company_id === parseInt(navId);
  });

  const nameProject = projects.filter((d) => {
    return d.project_id === parseInt(navId);
  });

  const selectedCompanyName =
    mainLocation === "edit-company" ? name[0]?.name : nameProject[0]?.name;

  const submitHandlerTask = async () => {
    if (
      !regName.test(formData.subject) ||
      formData?.subject?.length == undefined
    ) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Task Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      const formattedDateRange = `${date.startdate} to ${date.enddate}`;

      let new_task_details = {
        ...formData,
        company_id: navId,
      };
      new_task_details = {
        ...new_task_details,
        startdate: formattedDateRange,
        OPtype: "Task",
        operation: "add",
        task_type: "keep",
      };
      const {
        success,
        data: { message },
      } = await sendRequest(apipaths.addTask, new_task_details);
      if (success) {
        dispatch(taskListAction());
        handleCancel();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Task added successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const submitHandlerProject = async () => {
    const formattedDateRange = `${date.startdate} to ${date.enddate}`;

    let new_task_details = {
      ...formData,
      project_id: navId,
    };
    new_task_details = {
      ...new_task_details,
      startdate: formattedDateRange,
      OPtype: "Task",
      operation: "add",
      task_type: "keep",
    };
    const {
      success,
      // data: { message },
    } = await sendRequest(apipaths.addTask, new_task_details);
    if (success) {
      dispatch(taskListAction());
      handleCancel();
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Task added successfully !",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const fillClient = clients.filter((d) => {
    return d.company_id == navId;
  });

  const fillProject = projects.filter((d) => {
    return d.client_id == formData.client_id;
  });

  const fillCounter = companies.filter((d) => {
    return d.company_id != navId;
  });

  const fillCounterClient = clients.filter((d) => {
    return d.company_id == formData.cp_company_id;
  });

  const fillCounterProject = projects.filter((d) => {
    return d.client_id == formData.cp_client_id;
  });
  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFormData("");
    setEditData("");
  };

  const updateTaskHandler = async () => {
    if (!regName.test(formData.subject)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Task Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      const allData = {
        id: editData.task_id,
        subject: formData.subject,
        desc: formData.desc,
        remarks: formData.remarks,
        priority: formData.priority,
        client_id: formData.client_id,
        project_id: formData.project_id,
        status: formData.status,
        employee_id: formData.employee_id,
        operation: "update",
        task_type: "update",
        OPtype: "Task",
        startdate: date,
      };

      const { success } = await sendRequest(apipaths.addTask, allData);
      if (success) {
        dispatch(taskListAction());
        handleCancel();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Task Update Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const updateProjectHandler = async () => {
    const allData = {
      id: editData.task_id,
      subject: formData.subject,
      desc: formData.desc,
      remarks: formData.remarks,
      priority: formData.priority,
      client_id: formData.client_id,
      project_id: formData.project_id,
      status: formData.status,
      employee_id: formData.employee_id,
      operation: "update",
      task_type: "update",
      OPtype: "Task",
      startdate: date,
    };

    const { success } = await sendRequest(apipaths.addTask, allData);
    if (success) {
      dispatch(taskListAction());
      handleCancel();
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Task Update succesfully",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const mainHandler = () => {
    if (editData.task_id) {
      if (mainLocation === "edit-company") {
        updateTaskHandler();
      } else {
        updateProjectHandler();
      }
    } else {
      if (mainLocation === "edit-company") {
        submitHandlerTask();
      } else {
        submitHandlerProject();
      }
    }
  };

  const handleEvent = (event, picker) => {
    const time = "12:00"; // Define the time to append
    const startdate = formatDateList(event[0]) + " " + time;
    const enddate = formatDateList(event[1]) + " " + time;
  
    setDate({
      startdate,
      enddate,
    });
  };

  const dateChange = (date) => {
    handleEvent(date);
    setValue(date);
  };

  return (
    <Modal
      destroyOnClose
      // closable={false}
      width={900}
      centered
      title={false}
      visible={isModalVisible}
      maskClosable={false}
      onCancel={handleCancel}
      footer={false}
      className="company-task-modal"
    >
      <div
        className="line px-3 d-flex justify-content-between align-items-center"
        style={{ clear: "both", borderBottom: "1px solid #f0f0f0" }}
      >
        <h5>{translation.task}</h5>
        <h5 className="text-success pe-5">{selectedCompanyName}</h5>
      </div>
      <hr />
      <form>
        <div className="w-100 p-2 row px-4">
          <div class="mb-2 col-lg-8 mt-2">
            <label for="subject" class="form-label">
              {translation.subject}
              <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              id="subject"
              placeholder={translation.subject}
              name="subject"
              key="subject"
              value={formData.subject}
              defaultValue={editData.subject}
              required
              onChange={onInputChange}
            />
          </div>
          <div className="mb-2 col-lg-4  mt-3">
            <label for="startdate" class="form-label">
              {translation.startDate_to_endDate}
            </label>
            {editData.startdate ? (
              <RangePicker
                onChange={(date) => dateChange(date)}
                defaultValue={[
                  moment(formatDateList(editData.startdate)),
                  moment(formatDateList(editData.enddate)),
                ]}
                className="form-control border-none rounded-lg"
                placeholder={[translation.start__end_date]}
                format="YYYY/MM/DD"
              />
            ) : (
              <RangePicker
                onChange={(date) => dateChange(date)}
                className="form-control border-none rounded-lg"
                placeholder={[translation.start__end_date]}
                format="YYYY/MM/DD"
              />
            )}
          </div>
          <div class="mb-2 col-lg-3  mt-2">
            <label for="priority" class="form-label">
              {translation.priority}
            </label>
            <select
              class="form-select"
              aria-label=" Select Priority"
              key="priority"
              onChange={onInputChange}
              defaultValue={editData.priority}
              name="priority"
            >
              <option selected>{translation.select_priority}</option>
              <option value="Urgent">Urgent</option>
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
          </div>

          {mainLocation === "edit-company" ? (
            <>
              <div class="mb-2 col-lg-3  mt-2">
                <label for="Client" class="form-label">
                  {translation.client}
                </label>
                <select
                  class="form-select"
                  aria-label=" Select Client"
                  key="client_id"
                  onChange={onInputChange}
                  name="client_id"
                  defaultValue={editData.client_id}
                >
                  {" "}
                  <option selected>{translation.select_client}</option>
                  {fillClient.map((c, i) => (
                    <option key={c.client_id} value={c.client_id}>
                      {c.first_name + "" + c.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="mb-2 col-lg-3  mt-2">
                <label for="Project" class="form-label">
                  {translation.project}
                </label>
                <select
                  class="form-select"
                  aria-label=" select Project"
                  id="project_id"
                  key="project_id"
                  onChange={onInputChange}
                  defaultValue={editData.project_id}
                  name="project_id"
                >
                  <option selected>{translation.select_project}</option>
                  {fillProject.map((p, i) => (
                    <option key={p.project_id} value={p.project_id}>
                      {p.name}
                    </option>
                  ))}
                </select>
              </div>{" "}
            </>
          ) : (
            ""
          )}
          <div class="mb-2 col-lg-3  mt-2">
            <label for="Counter Party" class="form-label">
              {translation.counterparty}
            </label>
            <select
              class="form-select"
              aria-label="Select Counter Party"
              key="cp_company_id"
              onChange={onInputChange}
              id="cp_company_id"
              name="cp_company_id"
              defaultValue={editData.cp_company_id}
            >
              <option selected>{translation.select_counter_party}</option>
              {fillCounter.map((d, i) => (
                <option key={d.company_id} value={d.company_id}>
                  {d.name}
                </option>
              ))}
            </select>
          </div>
          <div class="mb-2 col-lg-3  mt-2">
            <label for="CP Client" class="form-label">
              {translation.cpclient}
            </label>
            <select
              class="form-select"
              aria-label="Select CP Client"
              key="cp_client_id"
              onChange={onInputChange}
              id="cp_client_id"
              name="cp_client_id"
              defaultValue={editData.cp_client_id}
            >
              <option selected>{translation.select_cp_client}</option>
              {fillCounterClient.map((c, i) => (
                <option key={c.cp_client_id} value={c.cp_client_id}>
                  {c.first_name + "" + c.last_name}
                </option>
              ))}
            </select>
          </div>
          <div class="mb-2 col-lg-3  mt-2">
            <label for="CP Project" class="form-label">
              {translation.cpproject}
            </label>
            <select
              class="form-select"
              aria-label="Select CP Project"
              key="cp_project_id"
              onChange={onInputChange}
              defaultValue={editData.cp_project_id}
              id="cp_project_id"
              name="cp_project_id"
            >
              <option selected>{translation.select_cp_project}</option>
              {fillCounterProject.map((p, i) => (
                <option key={p.project_id} value={p.project_id}>
                  {p.name}
                </option>
              ))}
            </select>
          </div>
          <div class="mb-2 col-lg-3  mt-2">
            <label for="status" class="form-label">
              {translation.status}
            </label>
            <select
              class="form-select"
              aria-label=" select Activity Status"
              key="status"
              id="status"
              name="status"
              defaultValue={editData.status}
              onChange={onInputChange}
            >
              <option selected>{translation.select_status}</option>
              <option value="Pending">Pending</option>
              <option value="Started">Started</option>
              <option value="Inprogress">Inprogress</option>
              <option value="Completed">Completed</option>
            </select>
          </div>
          <div class="mb-2 col-lg-3  mt-2">
            <label for="Task Assigned To" class="form-label">
              {translation.task_assigned_to}
            </label>
            <select
              class="form-select"
              aria-label=" Select Task Assigned To"
              key="employee_id"
              onChange={onInputChange}
              defaultValue={editData.employee_id}
              id="employee_id"
              name="employee_id"
            >
              <option selected>{translation.select_task_asigned_to}</option>
              {employees.map((e, i) => (
                <option key={e.employee_id} value={e.employee_id}>
                  {e.first_name + " " + e.last_name}
                </option>
              ))}
            </select>
          </div>
          <div class="mb-2 col-lg-3  mt-2">
            <label for="Employee" class="form-label">
              {translation.employee}
            </label>
            <select
              class="form-select"
              aria-label=" Select Employee"
              key="employee_id"
              onChange={onInputChange}
              defaultValue={editData.employee_id}
              id="employee_id"
              name="employee_id"
            >
              <option selected>{translation.select_employee}</option>
              {employees.map((e, i) => (
                <option key={e.employee_id} value={e.employee_id}>
                  {e.first_name + " " + e.last_name}
                </option>
              ))}
            </select>
          </div>
          <div class="mb-2 col-lg-12  mt-2">
            <label for="Description" class="form-label">
              {translation.description}
            </label>
            <textarea
              class="form-control"
              id="desc"
              placeholder={translation.description}
              rows="3"
              name="desc"
              key="desc"
              defaultValue={editData.desc}
              onChange={onInputChange}
            ></textarea>
          </div>
          <div class="mb-2 col-lg-12  mt-2">
            <label for="statsu" class="form-label">
              {translation.remarks}
            </label>
            <textarea
              class="form-control"
              id="remarks"
              rows="3"
              key="remarks"
              name="remarks"
              placeholder={translation.remarks}
              onChange={onInputChange}
              defaultValue={editData.remarks}
            ></textarea>
          </div>

          <div class="col-lg-12 mb-2 d-flex justify-content-end px-2.5">
            <button
              type="button"
              class="btn btn-danger px-3 py-2 me-2"
              onClick={() => handleCancel()}
            >
              {translation.cancel}
            </button>
            <button
              type="button"
              class="btn btn-primary px-3 py-2"
              onClick={() => mainHandler()}
            >
              {editData.task_id || editData.project_id
                ? `${translation.update}`
                : `${translation.add}`}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default TaskCompanyForm;
