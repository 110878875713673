import moment from "moment";
import Swal from "sweetalert2";
import { formatMeetingDate, onSuccesshandler } from "../helpers/commonHelpers";
import { isCompanyId, isProjectId } from "../helpers/companyHelpers";
import { sendRequest } from "../services";

export async function addHandler(
  apiUrl,
  formdata,
  setModal,
  dispatch,
  renderlist,
  companyId
) {
  const form_data = formdata;
  if(form_data.role_name === 'Admin'){
    form_data['role_id'] = 2
  }
  if(form_data.role_name === 'Manager'){
    form_data['role_id'] = 3
  }
  if(form_data.role_name === 'Associate'){
    form_data['role_id'] = 4
  }

  form_data["operation"] = "add";
  const form_data_withId = isCompanyId(companyId, form_data);
  const { success, message } = await sendRequest(apiUrl, form_data_withId);
  if (success) {
    onSuccesshandler(setModal, dispatch, renderlist, message);
  }
}

export async function addProjectHandler(
  apiUrl,
  formdata,
  setModal,
  dispatch,
  renderlist,
  projectId
) {
  const form_data = formdata;
  form_data["operation"] = "add";
  const form_data_withId = isProjectId(projectId, form_data);
  const { success, message } = await sendRequest(apiUrl, form_data_withId);
  if (success) {
    onSuccesshandler(setModal, dispatch, renderlist, message);
  }
}

export async function addFormDatahandler(
  apiUrl,
  formdata,
  setModal,
  dispatch,
  renderlist,
  companyId
) {
  const form_data = new FormData();
  Object.keys(formdata).forEach((item) => {
    form_data.append(item, formdata[item]);
  });
  form_data.append("operation", "add");
  const { success, message } = await sendRequest(apiUrl, form_data);
  if (success) {
    onSuccesshandler(setModal, dispatch, renderlist, message);
  }
}

export async function deleteHandler(
  apiUrl,
  id,
  setModal,
  dispatch,
  renderList
) {
  Swal.fire({
    title: "Are you sure?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Delete",
    confirmButtonColor: "#1890ff",
  }).then(async ({ isConfirmed }) => {
    if (!isConfirmed) return null;
    const { success, message, data } = await sendRequest(apiUrl, {
      delete_id: id,
    });

    if (success) {
      onSuccesshandler(
        setModal,
        dispatch,
        renderList,
        message !== "" ? message : data?.[0]?.["original"]?.["message"]
      );
    }
  });
}

export async function updateHandler(
  apiUrl,
  editData,
  id,
  setModal,
  dispatch,
  renderList
) {
  const formdata = editData;

  formdata["id"] = editData[`${id}`];
  if(formdata.role_name === 'Admin'){
    formdata['role_id'] = 2
  }
  if(formdata.role_name === 'Manager'){
    formdata['role_id'] = 3
  }
  if(formdata.role_name === 'Associate'){
    formdata['role_id'] = 4
  }
  delete formdata[`${id}`];
  formdata["operation"] = "update";

  const { success, message } = await sendRequest(apiUrl, formdata);
  if (success) {
    onSuccesshandler(setModal, dispatch, renderList, message);
  }
}

export async function addProjectDataTableHandler(
  apiUrl,
  formdata,
  setModal,
  dispatch,
  renderlist,
  projectId,
  clientId,
  companyId
) {
  formdata["operation"] = "add";
  formdata["project_id"] = projectId;
  formdata["client_id"] = clientId;
  formdata["company_id"] = companyId;

  let formData = new FormData();

  // Check if document or attachment_file exist and append them to formData
  if (formdata.attachment_file) {
    Object.keys(formdata).forEach(key => {
      const value = formdata[key];
      // Append files as File objects, and regular values normally
      if (value instanceof File) {
        formData.append(key, value);
      } else {
        formData.append(key, value);
      }
    });

    // If both document and attachment_file exist, append them separately
    if (formdata.document) {
      formData.append("document", formdata.document); // Ensure this is a File object
    }
    
    // Send the formData with files
    const { success, message } = await sendRequest(apiUrl, formData);
    if (success) {
      onSuccesshandler(setModal, dispatch, renderlist, message);
    }
  } else if (formdata.document) {
    // If no attachment_file, but document exists, append only document data
    formData.append("title", formdata.title);
    formData.append("date", formdata.date);
    formData.append("company_id", formdata.company_id);
    formData.append("desc", formdata.desc);
    formData.append("document", formdata.document); // Ensure this is a File object
    formData.append("employee_id", formdata.employee_id);
    formData.append("project_id", formdata.project_id);
    formData.append("operation", "add");

    const { success, message } = await sendRequest(apiUrl, formData);
    if (success) {
      onSuccesshandler(setModal, dispatch, renderlist, message);
    }
  } else {
    // If no files are present, send formdata as-is
    const { success, message } = await sendRequest(apiUrl, formdata);
    if (success) {
      onSuccesshandler(setModal, dispatch, renderlist, message);
    }
  }
}

