import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import CardContainer from "../Card/cardContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  formatDateList,
  modalInitialValue,
  regName,
} from "../../helpers/commonHelpers";
import { taskListAction } from "../actions/taskListAction";
import { sendRequest } from "../../services";
import Swal from "sweetalert2";
import { apipaths } from "../../services/apiPaths";
import { Tabs } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import useTranslation from "../customHooks/translations";
import "rsuite/dist/rsuite.css";
import "./style.css";
import { DatePicker } from "antd";
import * as moment from "moment";
import { BsFillTrashFill } from "react-icons/bs";

const { RangePicker } = DatePicker;

const EditTask = () => {
  const [modal, setModal] = useState(modalInitialValue);
  const [selectTask, setSelectTask] = useState({});
  const [formData, setFormData] = useState({});
  const [date, setDate] = useState("");

  const translation = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: taskId } = useParams();

  const { tasks } = useSelector((state) => state.task);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);

  useEffect(() => {
    dispatch(taskListAction());
  }, [dispatch]);

  useEffect(() => {
    if (tasks.length > 0) {
      let taskMap =
        tasks &&
        tasks.filter((com) => parseInt(com.task_id) === parseInt(taskId));
        setSelectTask(taskMap[0]);

        if(taskMap[0]?.sub_task.length > 0){
          setInputList(taskMap[0].sub_task);
        }
    }
  }, [tasks]);

  const userDate1 = formatDateList(selectTask?.startdate);
  const [value, setValue] = React.useState([
    new Date(userDate1),
    new Date(userDate1),
  ]);

  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handleEvent = (event, picker) => {
    setDate(
      formatDateList(event[0]) + " " + "-" + " " + formatDateList(event[1])
    );
  };

  const fillClient = clients.filter((d) => {
    return d.company_id ;
  });

  const fillProject = projects.filter((d) => {
    return d.client_id;
  });
  const fillCPClient = clients.filter((d) => {
    return d.company_id ;
  });

  const fillCpProject = projects.filter((d) => {
    return d.client_id;
  });

  const CPParty = companies.filter((d) => {
    return d.company_id;
  });

  const updateTaskHandler = async () => {
    if (!regName.test(formData.subject)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Task Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      formData["id"] = selectTask.task_id;
      formData["operation"] = "update";
      formData["task_type"] = "update";
      formData["OPtype"] = "Task";
      formData["startdate"] = date;

      inputList.forEach((subTask, index) => {
        formData[`sub_task_${index + 1}`] = subTask.sub_task;
        formData[`due_date_${index + 1}`] = subTask.due_date;
        formData[`time_${index + 1}`] = subTask.time;
        formData[`status_${index + 1}`] = subTask.status;
        formData[`employee_id_${index + 1}`] = subTask.employee_id;
        formData[`acceptance_${index + 1}`] = subTask.acceptance;
      });


      const { success } = await sendRequest(apipaths.addTask, formData);
      if (success) {
        dispatch(taskListAction());
        setModal(modalInitialValue);
        navigate(-1);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Task Update Succesfully",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const dateChange = (date) => {
    handleEvent(date);
    setValue(date);
  };

  const [inputList, setInputList] = useState([
    {
      sub_task: "",
      due_date: "",
      time: "",
      status: "",
      employee_id: "",
      operation: "add",
      task_type: "close",
    },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setInputList((prevInputList) =>
      prevInputList.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      )
    );
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = (i) => {
    setInputList([
      ...inputList,
      {
        sub_task: "",
        due_date: "",
        time: "",
        status: "",
        employee_id: "",
        operation: "add",
        task_type: "close",
      },
    ]);
  };

  return (
    <>
      <div className="main-container">
        <Heading title="Edit Task" />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="main-content-wrapper">
          <Tabs defaultActiveKey="1" onChange={(key) => key}>
            <Tabs.TabPane tab={translation.task} key="item-1" className="row">
              <div class="mb-2 col-lg-8  mt-3">
                <label for="subject" class="form-label">
                  {translation.subject}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="subject"
                  aria-describedby="emailHelp"
                  placeholder="Subject"
                  name="subject"
                  key="subject"
                  defaultValue={selectTask.subject}
                  onChange={onInputChange}
                />
              </div>
              <div class="mb-2 col-lg-4  mt-3">
                <label for="startdate" class="form-label">
                  {translation.startDate_to_endDate}
                </label>
                <RangePicker
                  onChange={(date) => dateChange(date)}
                  className="form-control border-none rounded-lg"
                  defaultValue={[
                    moment(formatDateList(selectTask.startdate)),
                    moment(formatDateList(selectTask.enddate)),
                  ]}
                  placeholder={[translation.start__end_date]}
                  format="YYYY/MM/DD"
                  position="bottom-left"
                />
              </div>
              <div class="mb-2 col-lg-3  mt-3">
                <label for="priority" class="form-label">
                  {translation.priority}
                </label>
                <select
                  class="form-select"
                  aria-label=" Select Priority"
                  key="priority"
                  defaultValue={selectTask.priority}
                  onChange={onInputChange}
                  name="priority"
                >
                  {" "}
                  <option selected>{translation.select_priority}</option>
                  <option value="Urgent">Urgent</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
              <div class="mb-2 col-lg-3  mt-3">
                <label for="Client" class="form-label">
                  {translation.company}
                </label>
                <select
                  class="form-select"
                  aria-label=" Select Client"
                  key="company_id"
                  onChange={onInputChange}
                  defaultValue={selectTask.company_id}
                  name="company_id"
                >
                  {" "}
                  <option selected>{translation.select_company}</option>
                  {companies.map((d, i) => (
                    <option key={d.company_id} value={d.company_id}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </div>

              <div class="mb-2 col-lg-3  mt-3">
                <label for="Client" class="form-label">
                  {translation.client}
                </label>
                <select
                  class="form-select"
                  aria-label=" Select Client"
                  key="client_id"
                  onChange={onInputChange}
                  defaultValue={selectTask.client_id}
                  name="client_id"
                >
                  {" "}
                  <option selected>{translation.select_client}</option>
                  {fillClient.map((c, i) => (
                    <option key={c.client_id} value={c.client_id}>
                      {c.first_name + "" + c.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="mb-2 col-lg-3  mt-3">
                <label for="Project" class="form-label">
                  {translation.project}
                </label>
                <select
                  class="form-select"
                  aria-label=" select Project"
                  id="project_id"
                  key="project_id"
                  onChange={onInputChange}
                  defaultValue={selectTask?.project_id}
                  name="project_id"
                >
                  <option selected>{translation.select_project}</option>
                  {fillProject.map((p, i) => (
                    <option key={p.project_id} value={p.project_id}>
                      {p.name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="mb-2 col-lg-3  mt-3">
                <label for="Counter Party" class="form-label">
                  {translation.counterparty}
                </label>
                <select
                  class="form-select"
                  aria-label="Select Counter Party"
                  key="cp_company_id"
                  onChange={onInputChange}
                  defaultValue={selectTask.cp_company_id}
                  id="cp_company_id"
                  name="cp_company_id"
                >
                  <option selected>{translation.select_counter_party}</option>

                  {CPParty.map((d, i) => (
                    <option key={d.company_id} value={d.company_id}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="mb-2 col-lg-3  mt-3">
                <label for="CP Client" class="form-label">
                  {translation.cpclient}
                </label>
                <select
                  class="form-select"
                  aria-label="Select CP Client"
                  key="cp_client_id"
                  onChange={onInputChange}
                  defaultValue={selectTask?.cp_client_id}
                  id="cp_client_id"
                  name="cp_client_id"
                >
                  <option selected>{translation.select_cp_client}</option>
                  {fillCPClient.map((c, i) => (
                    <option key={c.client_id} value={c.client_id}>
                      {c.first_name + "" + c.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="mb-2 col-lg-3  mt-3">
                <label for="CP Project" class="form-label">
                  {translation.cpproject}
                </label>
                <select
                  class="form-select"
                  aria-label="Select CP Project"
                  key="cp_project_id"
                  onChange={onInputChange}
                  defaultValue={selectTask.cp_project_id}
                  id="cp_project_id"
                  name="cp_project_id"
                >
                  <option selected>{translation.select_cp_project}</option>
                  {fillCpProject.map((p, i) => (
                    <option key={p.project_id} value={p.project_id}>
                      {p.name}
                    </option>
                  ))}
                </select>
              </div>

              <div class="mb-2 col-lg-3  mt-3">
                <label for="status" class="form-label">
                  {translation.status}
                </label>
                <select
                  class="form-select"
                  aria-label=" Select Status"
                  key="status"
                  defaultValue={selectTask.status}
                  onChange={onInputChange}
                  name="status"
                >
                  <option selected>{translation.select_status}</option>
                  <option value="Waiting">Waiting</option>
                  <option value="In Progress">Inprogress</option>
                </select>
              </div>
              <div class="mb-2 col-lg-3  mt-3">
                <label for="Task Assigned To" class="form-label">
                  {translation.task_assigned_to}
                </label>
                <select
                  class="form-select"
                  aria-label=" Select Task Assigned To"
                  key="employee_id"
                  onChange={onInputChange}
                  defaultValue={selectTask.employee_id}
                  id="employee_id"
                  name="employee_id"
                >
                  <option selected>{translation.select_task_asigned_to}</option>
                  {employees.map((e, i) => (
                    <option key={e.employee_id} value={e.employee_id}>
                      {e.first_name + " " + e.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="mb-2 col-lg-3  mt-3">
                <label for="Employee" class="form-label">
                  {translation.employee}
                </label>
                <select
                  class="form-select"
                  aria-label=" Select Employee"
                  key="employee_id"
                  onChange={onInputChange}
                  defaultValue={selectTask.employee_id}
                  id="employee_id"
                  name="employee_id"
                >
                  <option selected>{translation.select_employee}</option>
                  {employees.map((e, i) => (
                    <option key={e.employee_id} value={e.employee_id}>
                      {e.first_name + " " + e.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="mb-2 col-lg-12  mt-3">
                <label for="Description" class="form-label">
                  {translation.description}
                </label>
                <textarea
                  class="form-control"
                  id="desc"
                  placeholder={translation.description}
                  rows="3"
                  name="desc"
                  key="desc"
                  defaultValue={selectTask.desc}
                  onChange={onInputChange}
                ></textarea>
              </div>
              <div class="mb-2 col-lg-12  mt-3">
                <label for="statsu" class="form-label">
                  {translation.remarks}
                </label>
                <textarea
                  class="form-control"
                  id="remarks"
                  rows="3"
                  key="remarks"
                  name="remarks"
                  placeholder={translation.remarks}
                  defaultValue={selectTask.remarks}
                  onChange={onInputChange}
                ></textarea>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={translation.sub_task}
              key="item-2"
              className="row"
            >
              {inputList.map((x, i) => {
                return (
                  <>
                    <div class="mb-2 col-lg-2">
                      <label for="Sub Task" class="form-label">
                        {translation.sub_task}
                      </label>

                      <input
                        name="sub_task"
                        key="sub_task"
                        class="form-control"
                        placeholder={translation.sub_task}
                        value={x.sub_task}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </div>
                    <div class="mb-2 col-lg-2">
                      <label for="Due Date" class="form-label">
                        {translation.due_date}
                      </label>

                      <input
                        type="date"
                        className="ml10"
                        name="due_date"
                        key="due_date"
                        class="form-control"
                        placeholder={translation.due_date}
                        value={x.due_date}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </div>
                    <div class="mb-2 col-lg-2">
                      <label for="Time" class="form-label">
                        {translation.time}
                      </label>

                      <input
                        className="ml10"
                        type="time"
                        name="time"
                        key="time"
                        class="form-control"
                        placeholder={translation.time}
                        value={x.time}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </div>
                    <div class="mb-2 col-lg-3">
                      <label for="Status" class="form-label">
                        {translation.status}
                      </label>
                      <select
                        class="form-select"
                        name="status"
                        key="status"
                        value={x.status}
                        onChange={(e) => handleInputChange(e, i)}
                      >
                        <option selected>{translation.select_status}</option>
                         <option value="Waiting">Waiting</option>
                            <option value="In Progress">Inprogress</option>
                      </select>
                    </div>

                    <div class="mb-2 col-lg-2">
                      <label for="Assignee" class="form-label">
                        {translation.assignee}
                      </label>
                      <select
                        class="form-select"
                        aria-label=" Select Assignee"
                        key="employee_id"
                        defaultValue={selectTask.employee_id}
                        onChange={(e) => handleInputChange(e, i)}
                        name="employee_id"
                      >
                        <option selected>{translation.select_employee}</option>
                        {employees.map((e, i) => (
                          <option key={e.employee_id} value={e.employee_id}>
                            {e.first_name + " " + e.last_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {i === 0 ? (
                      ""
                    ) : (
                      <BsFillTrashFill
                        onClick={() => handleRemoveClick(i)}
                        style={{ marginTop: "35px" }}
                        className="table-icons delete-icon text-danger col-lg-1"
                      />
                    )}
                    {inputList.length - 1 === i && (
                      <div className="ps-2">
                        <button
                          class="btn btn-primary px-3 py-2 me-2 mt-1 d-block"
                          onClick={() => handleAddClick(i)}
                        >
                          {translation.add}
                        </button>
                      </div>
                    )}
                  </>
                );
              })}
            </Tabs.TabPane>
          </Tabs>
          {/* )} */}
          <div class="col-lg-12 mb-2 d-flex justify-content-end ">
            <button
              type="button"
              class="btn btn-danger px-3 py-2 me-2"
              onClick={() => onCancel()}
            >
              {translation.cancel}
            </button>
            <button
              type="button"
              class="btn btn-primary px-3 py-2"
              onClick={() => updateTaskHandler()}
            >
              {translation.update}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTask;
