import { DatePicker, Modal } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { formatDateList, regName } from "../../helpers/commonHelpers";
import { addActionColumn } from "../../helpers/companyHelpers";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import { notesListAction } from "../actions/notesAction";
import { taskListAction } from "../actions/taskListAction";
import useTranslation from "../customHooks/translations";
import TableCard from "../TableCard";

const ActionPlan = ({ setModal, companyId, projectId }) => {
  const [formData, setFormData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderdata, setOrderData] = useState([]);
  const [editData, setEditData] = useState({});
  const [date, setDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const dispatch = useDispatch();

  const { tasks } = useSelector((state) => state.task);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);
  const { notes } = useSelector((state) => state.notes);
  const translation = useTranslation();
  const location = useLocation();

  const mainLocation = location.pathname.slice(1, -4);

  const name = companies.filter((d) => {
    return d.company_id === parseInt(companyId);
  });

  const nameProject = projects.filter((d) => {
    return d.project_id === parseInt(projectId);
  });

  const selectedProjectName =
    mainLocation === "edit-company" ? name[0]?.name : nameProject[0]?.name;

  const actionColumn = [
    {
      title: "ID",
      dataIndex: "action_plan_id",
      sorter: (a, b) => a.action_plan_id - b.action_plan_id,
    },
    {
      title: "Action Title",
      dataIndex: "action_title",
      sorter: (a, b) => a.action_title.localeCompare(b.action_title),
      render: (action_title, row) => {
        return (
          <label
            onClick={() => {
              editHandler(row);
            }}
          >
            {action_title}
          </label>
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      sorter: (a, b) => a.priority.localeCompare(b.priority),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Tasks",
      dataIndex: "task",
      sorter: (a, b) => a?.task?.subject.localeCompare(b?.task?.subject),
      render: (task_id, row) => {
        return row?.task?.subject;
      },
    },
    {
      title: "Notes",
      dataIndex: "note",
      sorter: (a, b) => a?.note?.title.localeCompare(b?.note?.title),
      render: (counter_party_id, row) => {
        return row?.note?.title;
      },
    },
  ];
  const actionColumnHome = [
    {
      title: "ID",
      dataIndex: "action_plan_id",
      sorter: (a, b) => a.action_plan_id - b.action_plan_id,
    },
    {
      title: "アクションタイトル",
      dataIndex: "action_title",
      sorter: (a, b) => a.action_title.localeCompare(b.action_title),
      render: (action_title, row) => {
        return (
          <label
            onClick={() => {
              editHandler(row);
            }}
          >
            {action_title}
          </label>
        );
      },
    },
    {
      title: "優先順位",
      dataIndex: "priority",
      sorter: (a, b) => a.priority.localeCompare(b.priority),
    },
    {
      title: "スターテス",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "タスク",
      dataIndex: "task_id",
      sorter: (a, b) => a?.task?.subject.localeCompare(b?.task?.subject),
      render: (task_id, row) => {
        const task = row["task"];
        if (task) return task.subject;
        else return "";
      },
    },
    {
      title: "ノート",
      dataIndex: "note_id",
      sorter: (a, b) => a?.note?.title.localeCompare(b?.note?.title),
      render: (note_id, row) => {
        const note = row["note"];
        if (note) return note.title;
        else return "";
      },
    },
  ];

  useEffect(() => {
    OrderList();
    dispatch(notesListAction());
    dispatch(taskListAction());
  }, [dispatch]);

  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setFormData("");
    setEditData("");
  };

  const updateHandler = async () => {
    if (!regName.test(formData.action_title)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Action Plan!",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
    let allData = {
      id: editData.action_plan_id,
      start_date: date,
      end_date: lastDate,
      ...formData,
    };

    const {
      success,
      data: {  },
    } = await sendRequest(apipaths.actionplanUpdate, allData);
    if (success) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Action Plan Update Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      OrderList();
      setIsModalVisible(false);
      handleCancel();
      setIsModalVisible(false);
      OrderList();
    }
  }
  };

  const submitHandler = async () => {
    if (
      !regName.test(formData.action_title) ||
      formData?.action_title?.length === undefined
    ) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Action Plan !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
    let new_task_details = {
      action_title: formData.action_title,
      desc: formData.desc,
      priority: formData.priority,
      start_date: date,
      end_date: lastDate,
      status: formData.status,
      remarks: formData.remarks,
      task_id: formData.task_id,
      employee_id: formData.employee_id,
      note_id: formData.note_id,
      enable: "1",
    };
    const { success } = await sendRequest(
      apipaths.actionplan,
      new_task_details
    );
    if (success) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Action Plan Added successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      OrderList();
    }
  
    setIsModalVisible(false);
  }
  };

  const mainHandler = () => {
    if (editData.action_plan_id) {
      updateHandler();
    } else {
      submitHandler();
    }
  
  };

  const OrderList = async () => {
    const { error, data } = await sendRequest(apipaths.actionplanList);
    if (error) {
      let message = error.error ? error.error : error.message;
      return message
    } else {
      setOrderData(data.ActionPlan);
    }
  };

  const editHandler = (allData) => {
    setEditData(allData);
    setIsModalVisible(true);
  };

  const deleteTaskHandler = (actionData) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data } = await sendRequest(apipaths.deleteactionplan, {
        delete_id: actionData.action_plan_id,
      });
      const { success, message } = data[0].original;
      if (success === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      OrderList();
    });
  };

  const dateChange = (date) => {
    setDate(formatDateList(date));
  };

  const lastDateChange = (date) => {
    setLastDate(formatDateList(date));
  };

  return (
    <>
      <TableCard
        title={translation.action_plan}
        button={{
          title: translation.add_action_plan,
          onClick: () => showModal(),
        }}
        columns={addActionColumn(
          {},
          actionColumn,
          (allData) => editHandler(allData),
          (data) => deleteTaskHandler(data),

          translation._language
        )}
        jaCol={addActionColumn(
          {},
          actionColumnHome,
          (allData) => editHandler(allData),
          (data) => deleteTaskHandler(data),
          translation._language
        )}
        data={orderdata}
      />
      <Modal
        destroyOnClose
        width={900}
        centered
        // title={translation.add_action_plan}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={false}
        maskClosable={false}
        className="model"
      >
        <div
          className="line px-3 d-flex justify-content-between align-items-center"
          style={{
            clear: "both",
            width: "100%",
            borderBottom: " 1px solid #f0f0f0",
          }}
        >
          <h5>{translation.action_plan}</h5>
          <h5 className="text-success pe-5">{selectedProjectName}</h5>
        </div>
        <form>
          <div className="w-100 p-2 row px-4">
            <div className="mt-3 col-sm-12 col-md-6 col-lg-3">
              <label for="Start Time" className="form-label">
                {translation.action_title}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="action_title"
                aria-describedby="emailHelp"
                placeholder={translation.action_title}
                name="action_title"
                key="action_title"
                onChange={onInputChange}
                value={formData.action_title}
                defaultValue={editData.action_title}
              />
            </div>
            <div className="mt-3 col-lg-9 col-md-6 col-sm-12">
              <label for="location" className="form-label">
                {translation.status}
              </label>
              <input
                type="text"
                className="form-control"
                id="status"
                aria-describedby="emailHelp"
                placeholder={translation.status}
                name="status"
                key="status"
                onChange={onInputChange}
                defaultValue={editData.status}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-2">
              <label for="partnership_mode" className="form-label">
                {translation.start_date}
              </label>
              {editData.start_date ? (
                <DatePicker
                  className="w-100"
                  name="start_date"
                  key="start_date"
                  placeholder={translation.select_date}
                  onChange={(date) => dateChange(date)}
                  style={{ borderRadius: "0.375rem" }}
                  defaultValue={moment(editData.start_date)}
                />
              ) : (
                <DatePicker
                  className="w-100"
                  name="start_date"
                  key="start_date"
                  placeholder={translation.select_date}
                  onChange={(date) => dateChange(date)}
                  style={{ borderRadius: "0.375rem" }}
                  defaultValue={moment(editData.start_date)}
                />
              )}
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-2">
              <label for="partnership_mode" className="form-label">
                {translation.end_date}
              </label>
              {editData.end_date ? (
                <DatePicker
                  className="w-100"
                  name="end_date"
                  key="end_date"
                  placeholder={translation.select_date}
                  onChange={(date) => lastDateChange(date)}
                  defaultValue={moment(editData.end_date)}
                  style={{ borderRadius: "0.375rem" }}
                />
              ) : (
                <DatePicker
                  className="w-100"
                  name="end_date"
                  key="end_date"
                  placeholder={translation.select_date}
                  onChange={(date) => lastDateChange(date)}
                  defaultValue={moment(editData.end_date)}
                  style={{ borderRadius: "0.375rem" }}
                />
              )}
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-2">
              <label for="priority" className="form-label">
                {translation.priority}
              </label>
              <select
                className="form-select"
                aria-label="Select Priority"
                key="priority"
                onChange={onInputChange}
                defaultValue={editData.priority}
                name="priority"
              >
                <option selected>{translation.select_priority}</option>
                <option value="Urgent">Urgent</option>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>

            <div className="col-12 col-md-6 col-lg-3 mt-2">
              <label for="priority" className="form-label">
                {translation.notes}
              </label>
              <select
                className="form-select"
                aria-label=" Select notes"
                key="note_id"
                onChange={onInputChange}
                defaultValue={editData.note_id}
                name="note_id"
              >
                {" "}
                <option selected>{translation.select_notes}</option>
                {notes.map((d) => (
                  <option key={d.note_id} value={d.note_id}>
                    {d.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-2 col-lg-3  mt-2">
              <label for="Task Assigned To" className="form-label">
                {translation.task_assigned_to}
              </label>
              <select
                className="form-select"
                aria-label=" Select Task Assigned To"
                key="assignee_id"
                onChange={onInputChange}
                defaultValue={editData.assignee_id}
                id="assignee_id"
                name="assignee_id"
              >
                <option selected>{translation.select_assignee}</option>
                {employees.map((e, i) => (
                  <option key={e.employee_id} value={e.employee_id}>
                    {e.first_name + " " + e.last_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-2 col-lg-3  mt-2">
              <label for="priority" className="form-label">
                {translation.task}
              </label>
              <select
                className="form-select"
                aria-label=" Select Task"
                key="task_id"
                onChange={onInputChange}
                defaultValue={editData.task_id}
                name="task_id"
              >
                {" "}
                <option selected>{translation.select_task}</option>
                {tasks.map((e, i) => (
                  <option key={e.task_id} value={e.task_id}>
                    {e.subject}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-2">
              <label for="partnership_mode" className="form-label">
                {translation.attachment}
              </label>
              <input
                type="file"
                className="form-control"
                id="attachment"
                aria-describedby="emailHelp"
                placeholder={translation.attachment}
                name="attachment"
                key="attachment"
                onChange={onInputChange}
                defaultValue={editData.attachment}
              />
            </div>
            <div className="mb-2 col-lg-12  mt-3">
              <label for="remarks" className="form-label">
                {translation.remarks}
              </label>
              <textarea
                className="form-control"
                id="remarks"
                placeholder={translation.remarks}
                rows="3"
                name="remarks"
                key="remarks"
                onChange={onInputChange}
                defaultValue={editData.remarks}
              ></textarea>
            </div>
            <div className="col-lg-12 mb-2 d-flex justify-content-end ">
              <button
                type="button"
                className="btn btn-danger px-3 py-2 me-2"
                onClick={() => handleCancel()}
              >
                {translation.cancel}
              </button>
              <button
                type="button"
                className="btn btn-primary px-3 py-2"
                onClick={() => mainHandler()}
              >
                {editData.action_plan_id ? translation.update : translation.add}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ActionPlan;
