import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import TableCard from "../TableCard";
import CardContainer from "../Card/cardContainer";
import { useDispatch, useSelector } from "react-redux";
import { projectListAction } from "../actions/projectAction";
import { addActionColumn } from "../../helpers/companyHelpers";
import {
  addPrefexToObjectProperty,
  companyFormLayoutHandler,
  formModalController,
  modalInitialValue,
} from "../../helpers/commonHelpers";
import {
  projectFormLayout,
  projectFormLayoutJap,
} from "../../Data/FormsLayout/projectFormLayout";
import ModalContainer from "../Modal";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";
import Swal from "sweetalert2";
import { companyListAction } from "../actions/comanyAction";
import { clientListAction } from "../actions/clientAction";
import { Link, useNavigate } from "react-router-dom";
import useTranslation from "../customHooks/translations";

function Project() {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(modalInitialValue);

  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { employees } = useSelector((state) => state.employee);

  const translation = useTranslation();
  const navigate = useNavigate();
  const pageLanguage = translation._language;

  useEffect(() => {
    dispatch(projectListAction());
    dispatch(companyListAction());
    dispatch(clientListAction());
  }, []);

  useEffect(() => {
    if (clients && companies && employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      let l1 = companyFormLayoutHandler(
        employees,
        projectFormLayout,
        "employee_id",
        "name"
      );
      let l2 = companyFormLayoutHandler(companies, l1, "company_id", "name");

      clients.map(
        (client) => (client.name = client.first_name + " " + client.last_name)
      );
      let l3 = companyFormLayoutHandler(clients, l2, "client_id", "name");
    }
    if (clients && companies && employees) {
      employees.map((emp) => (emp.name = emp.first_name + " " + emp.last_name));
      let l1 = companyFormLayoutHandler(
        employees,
        projectFormLayoutJap,
        "employee_id",
        "name"
      );
      let l2 = companyFormLayoutHandler(companies, l1, "company_id", "name");

      clients.map(
        (client) => (client.name = client.first_name + " " + client.last_name)
      );
      let l3 = companyFormLayoutHandler(clients, l2, "client_id", "name");
    }
  }, [clients, companies, employees]);

  const projectColumns = [
    {
      title: "ID",
      dataIndex: "project_id",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.project_id - b.project_id,
    },
    {
      title: "Name",
      dataIndex: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, data) => {
        return (
          <>
            <Link to={`/edit-project/${data.project_id}`}>
              <label>{name}</label>
            </Link>
          </>
        );
      },
    },
    {
      title: "Project Cost",
      dataIndex: "project_cost",
      sorter: (a, b) => a.project_cost.localeCompare(b.project_cost),
    },
    {
      title: "Company",
      dataIndex: "company",
      sorter: (a, b) => a.company.name.localeCompare(b.company.name),
      render: (company) => {
        return (
          <p className="mb-0">{company && company.name ? company.name : ""}</p>
        );
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) =>
        a?.client?.first_name.localeCompare(b?.client?.first_name),
      sorter: (a, b) => a.client.first_name.localeCompare(b.client.first_name),
      render: (client) => {
        if (!client) return null;
        const { first_name, last_name } = client;
        return <p className="mb-0">{first_name + " " + last_name}</p>;
      },
    },
    {
      title: "Activity Status",
      dataIndex: "activity_status",
      sorter: (a, b) => a.activity_status.localeCompare(b.activity_status),
      render: (activity_status) => {
        return <p className="mb-0">{activity_status}</p>;
      },
    },
    {
      title: "Stage",
      dataIndex: "stage",
      sorter: (a, b) => a.stage.localeCompare(b.stage),
    },
  ];

  const projectColumnsHome = [
    {
      title: "ID",
      dataIndex: "project_id",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.project_id - b.project_id,
    },
    {
      title: "名前",
      dataIndex: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, data) => {
        return (
          <>
            <Link to={`/edit-project/${data.project_id}`}>
              <label>{data.name}</label>
            </Link>
          </>
        );
      },
    },
    {
      title: "プロジェクト費用",
      dataIndex: "project_cost",
      sorter: (a, b) => a.project_cost.localeCompare(b.project_cost),
    },
    {
      title: "会社",
      dataIndex: "company",
      sorter: (a, b) => a.company.name.localeCompare(b.company.name),
      render: (company) => {
        return (
          <p className="mb-0">{company && company.name ? company.name : ""}</p>
        );
      },
    },
    {
      title: "クライアント",
      dataIndex: "client",
      sorter: (a, b) =>
        a?.client?.first_name.localeCompare(b?.client?.first_name),
      sorter: (a, b) => a.client.first_name.localeCompare(b.client.first_name),
      render: (client) => {
        if (!client) return null;
        const { first_name, last_name } = client;
        return <p className="mb-0">{first_name + " " + last_name}</p>;
      },
    },
    {
      title: "活動状態",
      dataIndex: "activity_status",
      sorter: (a, b) => a.activity_status.localeCompare(b.activity_status),
      render: (activity_status) => {
        return <p className="mb-0">{activity_status}</p>;
      },
    },
    {
      title: "ステージ",
      dataIndex: "stage",
      sorter: (a, b) => a.stage.localeCompare(b.stage),
    },
  ];

  const updateProjectHandler = async (project) => {
    project["id"] = project.project_id;
    delete project.project_id;
    delete project.enable;
    delete project.company;
    delete project.client;
    delete project.created_at;
    delete project.updated_at;
    delete project.user_id;
    delete project.edate;

    delete project.sdate;
    delete project.status;
    delete project.employee;
    delete project.company_project_profile_id;
    project = addPrefexToObjectProperty("Project_", project);
    project["operation"] = "update";
    project["project_type"] = "update";
    project["OPtype"] = "Project";
    const {
      data: { flash_success },
      success,
    } = await sendRequest(apipaths.addProject, project);
    if (success) {
      setModal(modalInitialValue);
      dispatch(projectListAction());
      Swal.fire({
        position: "center",
        icon: "success",
        title: flash_success,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const deleteProjectHandler = (project_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#1890ff",
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return null;

      const { data } = await sendRequest(apipaths.deleteProject, {
        delete_id: project_id,
      });
      const { success, message } = data[0].original;
      if (success === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(projectListAction());
      }
    });
  };

  const dataTest = (data) => {
    formModalController(
      setModal,
      projectFormLayout,
      projectFormLayoutJap,
      `${translation.edit_project}`,
      `edit-project-form`,
      data,
      (data) => updateProjectHandler(data)
    );
  };
  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />
      <div className="main-container">
        <Heading title={translation.project} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>

        <div className="px-5">
          <TableCard
            button={{
              title: translation.add_project,
              onClick: () => navigate("/project/form/add"),
            }}
            columns={addActionColumn(
              { type: `dropdown`, name: `project-dropdown` },
              projectColumns,
              (data) => {
                formModalController(
                  setModal,
                  projectFormLayout,
                  projectFormLayoutJap,
                  `${translation.edit_project}`,
                  `edit-Project-form`,
                  data,
                  (data) => updateProjectHandler(data)
                );
              },
              (project_id) => deleteProjectHandler(project_id),
              translation._language
            )}
            jaCol={addActionColumn(
              { type: `dropdown`, name: `project-dropdown` },
              projectColumnsHome,
              (data) => {
                formModalController(
                  setModal,
                  projectFormLayout,
                  projectFormLayoutJap,
                  `${translation.edit_project}`,
                  `edit-Project-form`,
                  data,
                  (data) => updateProjectHandler(data)
                );
              },
              (project_id) => deleteProjectHandler(project_id),
              translation._language
            )}
            data={projects}
            title={translation.project}
          />
        </div>
      </div>
    </>
  );
}

export default Project;
