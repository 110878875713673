import React, { useEffect, useState } from "react";
import {
  formatDate,
  formatDateList,
  modalInitialValue,
  regName,
} from "../../helpers/commonHelpers";
import ModalContainer from "../Modal";
import { Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Heading from "../Heading";
import CardContainer from "../Card/cardContainer";
import { meetingListAction } from "../actions/meetingAction";
import { apipaths } from "../../services/apiPaths";
import Swal from "sweetalert2";
import { sendRequest } from "../../services";
import "bootstrap/dist/css/bootstrap.css";
import useTranslation from "../customHooks/translations";
import * as moment from "moment";
import { DatePicker } from "antd";
import { employeesListAction } from "../actions/employeesListAction";
import { clientListAction } from "../actions/clientAction";
import { projectListAction } from "../actions/projectAction";
const { RangePicker } = DatePicker;

const EditMeeting = () => {
  const [modal, setModal] = useState(modalInitialValue);
  const [selectMeeting, setSelectMeeting] = useState({});
  const [formData, setFormData] = useState({});
  const [preStartDate, setPreStartDate] = useState({ startdate: "", enddate: "" });
  const [postStartDate, setPostStartDate] = useState({ startdate: "", enddate: "" });
  const [date, setDate] = useState("");

  const { employees } = useSelector((state) => state.employee);
  const { projects } = useSelector((state) => state.project);
  const { clients } = useSelector((state) => state.client);
  const { companies } = useSelector((state) => state.company);
  const { meet } = useSelector((state) => state.meet);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: meetingId } = useParams();
  const translation = useTranslation();

  useEffect(() => {
    if (meet.length > 0) {
      let meetMap = meet.filter(
        (com) => parseInt(com.meeting_id) === parseInt(meetingId)
      );
      setSelectMeeting(meetMap[0]);
    }
  }, [meet]);

  useEffect(() => {
    dispatch(meetingListAction());
    dispatch(employeesListAction());
    dispatch(clientListAction());
    dispatch(projectListAction());
  }, [dispatch]);

  const fillClient = clients.filter((d) => {
    return d.company_id;
  });

  const fillProject = projects.filter((d) => {
    return d.client_id;
  });

  const updateMeetingHandler = async () => {
    if (!regName.test(formData.subject)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Fill Out Meeting Subject !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {

      let form_data = {
        ...formData,
      };

      const formattedDateRange = `${preStartDate.startdate} to ${preStartDate.enddate}`;
      const formattedPostDateRange = `${postStartDate.startdate} to ${postStartDate.enddate}`;

      if(postStartDate.startdate !== '' && preStartDate.startdate !== ''){
        form_data = {
          ...form_data,
          startdate: date,
          Pre_startdate: formattedDateRange,
          Post_startdate: formattedPostDateRange,
        };
      }else if(postStartDate.startdate !== '') {
        form_data = {
          ...form_data,
          startdate: date,
          Post_startdate: formattedPostDateRange,
        };
      }else if(preStartDate.startdate !== ''){
        form_data = {
          ...form_data,
          startdate: date,
          Pre_startdate: formattedDateRange,
        };
      }else {
        form_data = {
          ...form_data,
          startdate: date,
        };
      }

      form_data["id"] = selectMeeting.meeting_id;
      form_data["operation"] = "update";

      delete form_data.created_at;
      delete form_data.updated_at;
      delete form_data.company;
      delete form_data.user_id;
      delete form_data.employee;
      delete form_data.meeting_id;
      delete form_data.enable;

      const { success } = await sendRequest(
        apipaths.addMeeting,
        form_data
      );
      if (success) {
        navigate(-1);
        dispatch(meetingListAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Meeting Update Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };
  const onInputChange = (e) => {
    const target = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const onCancel = () => {
    navigate(-1);
  };

  const handleEvent = (event, picker) => {
    const time = "12:00"; // Define the time to append
    const startdate = formatDateList(event[0]) + " " + time;
    const enddate = formatDateList(event[1]) + " " + time;
  
    setPreStartDate({
      startdate,
      enddate,
    });
  };

  const handleEventPost = (event, picker) => {
    const startdate = formatDate(event[0]);
    const enddate = formatDate(event[1]);
  
    setPostStartDate({
      startdate,
      enddate,
    });
  };

  const dateChange = (date) => {
    handleEvent(date);
  };

  const dateChangePost = (date) => {
    handleEventPost(date);
  };

  const selectDate = (date) => {
    setDate(formatDateList(date));
  };

  const dateFormat = "YYYY/MM/DD";

  return (
    <>
      <ModalContainer
        isOpen={modal.open}
        title={modal.title}
        closeIcon={() => setModal(modalInitialValue)}
        content={modal.content}
        footer={false}
      />

      <div className="main-container">
        <Heading title={translation.edit_meetings} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
        </div>
        <div className="main-content-wrapper">
          <Tabs defaultActiveKey="1" onChange={(key) => key}>
            <Tabs.TabPane
              tab={translation.meetings}
              key="item-1"
              className="row"
            >
              <div className="col-12 col-md-6 col-lg-9">
                <label for="Meetings" className="form-label">
                  {translation.meetings}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  aria-describedby="emailHelp"
                  placeholder="Meetings"
                  name="subject"
                  key="subject"
                  defaultValue={selectMeeting.subject}
                  onChange={onInputChange}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <label for="Date" className="form-label">
                  {translation.date}
                </label>
                {/* {selectMeeting.startdate ? ( */}
                <DatePicker
                  className="w-100"
                  name="startdate"
                  key="startdate"
                  value={selectDate?.date}
                  placeholder={translation.select_date}
                  onChange={(date) => selectDate(date)}
                  style={{ borderRadius: "0.375rem" }}
                  defaultValue={
                    selectMeeting.startdate ? moment(formatDateList(selectMeeting?.startdate)) : undefined
                  }                />
                {/* ) : ( */}
                {/* <DatePicker
                  className="w-100"
                  name="startdate"
                  key="startdate"
                  placeholder={translation.select_date}
                  onChange={(date) => selectDate(date)}
                  style={{ borderRadius: "0.375rem" }}
                /> */}
                {/* )} */}
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-3">
                <label for="Start Time" className="form-label">
                  {translation.start_time}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="starttime"
                  aria-describedby="emailHelp"
                  placeholder="Time"
                  name="starttime"
                  key="starttime"
                  defaultValue={selectMeeting.starttime}
                  onChange={onInputChange}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-3">
                <label for="End Time" className="form-label">
                  {translation.end_time}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="endtime"
                  aria-describedby="emailHelp"
                  placeholder="Time"
                  name="endtime"
                  key="endtime"
                  defaultValue={selectMeeting.endtime}
                  onChange={onInputChange}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-3">
                <label for="Venue" className="form-label">
                  {translation.venue}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="venue"
                  aria-describedby="emailHelp"
                  placeholder="Venue"
                  name="venue"
                  key="venue"
                  defaultValue={selectMeeting.venue}
                  onChange={onInputChange}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-3">
                <label for="Company" className="form-label">
                  {translation.company}
                </label>
                <select
                  className="form-select"
                  aria-label="Select Company"
                  key="company_id"
                  defaultValue={selectMeeting.company_id}
                  onChange={onInputChange}
                  name="company_id"
                >
                  {" "}
                  <option selected>{translation.select_company}</option>
                  {companies.map((d, i) => (
                    <option key={d.company_id} value={d.company_id}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-3">
                <label for="Client" className="form-label">
                  {translation.client}
                </label>
                <select
                  className="form-select"
                  aria-label=" Select Client"
                  key="client_id"
                  onChange={onInputChange}
                  defaultValue={selectMeeting.client_id}
                  name="client_id"
                >
                  {" "}
                  <option selected>{translation.select_client}</option>
                  {fillClient.map((c, i) => (
                    <option key={c.client_id} value={c.client_id}>
                      {c.first_name + "" + c.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-3">
                <label for="Project" className="form-label">
                  {translation.project}
                </label>
                <select
                  className="form-select"
                  aria-label=" select Project"
                  id="project_id"
                  key="project_id"
                  onChange={onInputChange}
                  defaultValue={selectMeeting.project_id}
                  name="project_id"
                >
                  <option selected>{translation.select_project}</option>
                  {fillProject.map((p, i) => (
                    <option key={p.project_id} value={p.project_id}>
                      {p.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-2 col-lg-3 mt-3">
                <label for="Incharge Employee" className="form-label">
                  {translation.incharge_employee}
                </label>
                <select
                  className="form-select"
                  aria-label="Select Incharge Employee"
                  key="employee_id"
                  onChange={onInputChange}
                  defaultValue={selectMeeting.employee_id}
                  id="employee_id"
                  name="employee_id"
                >
                  <option selected>
                    {translation.select_incharge_employee}
                  </option>
                  {employees.map((e, i) => (
                    <option key={e.employee_id} value={e.employee_id}>
                      {e.first_name + " " + e.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-6 col-lg-9 mt-3">
                <label for="Attendees" className="form-label">
                  {translation.attendees}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="attendees"
                  aria-describedby="emailHelp"
                  placeholder="Attendees"
                  name="attendees"
                  key="attendees"
                  defaultValue={selectMeeting.attendees}
                  onChange={onInputChange}
                />
              </div>

              <div className="col-12  mt-3">
                <label for="Description" className="form-label">
                  {translation.description}
                </label>
                <textarea
                  className="form-control"
                  id="desc"
                  placeholder="Description"
                  name="desc"
                  key="desc"
                  defaultValue={selectMeeting.desc}
                  onChange={onInputChange}
                ></textarea>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-3">
                <label for="Remarks" className="form-label">
                  {translation.remarks}
                </label>
                <textarea
                  className="form-control"
                  placeholder="Remarks"
                  id="remarks"
                  key="remarks"
                  defaultValue={selectMeeting.remarks}
                  onChange={onInputChange}
                  name="remarks"
                ></textarea>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-3">
                <label for="Keypoints" className="form-label">
                  {translation.keypoints}
                </label>
                <textarea
                  className="form-control"
                  id="keypoints"
                  key="keypoints"
                  name="keypoints"
                  placeholder="KeyPoints"
                  defaultValue={selectMeeting.keypoints}
                  onChange={onInputChange}
                ></textarea>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={translation.pre_task}
              key="item-2"
              className="row"
            >
              <div className="col-12 col-md-6 col-lg-8">
                <label for="Subject" className="form-label">
                  {translation.subject}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Pre_subject"
                  aria-describedby="emailHelp"
                  placeholder="Subject"
                  name="Pre_subject"
                  key="Pre_subject"
                  defaultValue={selectMeeting?.pre_task?.subject}
                  onChange={onInputChange}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <label for="Due Date" className="form-label">
                  {translation.start__end_date}
                </label>
                <RangePicker
                  className="form-control border-none rounded-lg"
                  onChange={(date) => dateChange(date)}
                  placeholder={["Start & End Date"]}
                   defaultValue={[
                      selectMeeting?.pre_task?.startdate
                        ? moment(formatDate(selectMeeting.pre_task.startdate, dateFormat))
                        : undefined,
                      selectMeeting?.pre_task?.enddate
                        ? moment(formatDate(selectMeeting.pre_task.enddate, dateFormat))
                        : undefined,
                    ]}
                    format={dateFormat}
                  />

                {/* <RangePicker
                  onChange={(date) => dateChange(date)}
                  defaultValue={[
                    moment(selectMeeting?.pre_task?.startdate, dateFormat),
                    moment(selectMeeting?.pre_task?.enddate, dateFormat),
                  ]}
                  format={dateFormat}
                /> */}
              </div>
              <div className="mb-2 col-lg-3 mt-3">
                <label for="Priority" className="form-label">
                  {translation.priority}
                </label>
                <select
                  className="form-select"
                  aria-label="Select Priority"
                  key="Pre_priority"
                  onChange={onInputChange}
                  id="Pre_priority"
                  defaultValue={selectMeeting?.pre_task?.priority}
                  name="Pre_priority"
                >
                  <option selected>Select Priority</option>
                  <option value="Urgent">Urgent</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
              <div className="mb-2 col-lg-3 mt-3">
                <label for="Status" className="form-label">
                  {translation.status}
                </label>
                <select
                  className="form-select"
                  aria-label=" Select Status"
                  key="Pre_status"
                  onChange={onInputChange}
                  name="Pre_status"
                  defaultValue={selectMeeting?.pre_task?.status}
                >
                  {" "}
                  <option selected>Select Status</option>
                  <option value="Pending">Pending</option>
                  <option value="Started">Started</option>
                  <option value="Inprogress">Inprogress</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
              <div className="mb-2 col-lg-3 mt-3">
                <label for="Task Assigned To" className="form-label">
                  {translation.task_assigned_to}
                </label>
                <select
                  className="form-select"
                  aria-label=" Select Task Assigned To"
                  key="Pre_task_assign_to"
                  onChange={onInputChange}
                  defaultValue={selectMeeting?.pre_task?.task_assign_to}
                  name="Pre_task_assign_to"
                >
                  {" "}
                  <option selected>Task Assigned To</option>
                  {employees.map((e, i) => (
                    <option key={e.employee_id} value={e.employee_id}>
                      {e.first_name + " " + e.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-2 col-lg-3 mt-3">
                <label for="Employee" className="form-label">
                  {translation.employee}
                </label>
                <select
                  className="form-select"
                  aria-label=" Select Employee"
                  key="Pre_employee_id"
                  onChange={onInputChange}
                  defaultValue={selectMeeting?.pre_task?.employee_id}
                  name="Pre_employee_id"
                >
                  {" "}
                  <option selected>Employee</option>
                  {employees.map((e, i) => (
                    <option key={e.employee_id} value={e.employee_id}>
                      {e.first_name + " " + e.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12  mt-2">
                <label for="Description" className="form-label">
                  {translation.description}
                </label>
                <textarea
                  className="form-control"
                  id="Pre_desc"
                  rows="3"
                  placeholder="Describe Here"
                  name="Pre_desc"
                  key="Pre_desc"
                  defaultValue={selectMeeting?.pre_task?.desc}
                  onChange={onInputChange}
                ></textarea>
              </div>
              <div className="col-12  mt-2">
                <label for="Remarks" className="form-label">
                  {translation.remarks}
                </label>
                <textarea
                  className="form-control"
                  id="Pre_remarks"
                  rows="3"
                  placeholder="Remarks Here"
                  name="Pre_remarks"
                  key="Pre_remarks"
                  defaultValue={selectMeeting?.pre_task?.remarks}
                  onChange={onInputChange}
                ></textarea>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={translation.post_task}
              key="item-3"
              className="row"
            >
              <div className="col-12 col-md-6 col-lg-8">
                <label for="Subject" className="form-label">
                  {translation.subject}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Post_subject"
                  aria-describedby="emailHelp"
                  placeholder="Subject"
                  name="Post_subject"
                  key="Post_subject"
                  defaultValue={selectMeeting?.post_task?.subject}
                  onChange={onInputChange}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <label for="Due Date" className="form-label">
                  {translation.start__end_date}
                </label>
                <RangePicker
                  className="form-control border-none rounded-lg"
                  placeholder={["Start & End Date"]}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => dateChangePost(date)}
                  defaultValue={[
                    selectMeeting?.post_task?.startdate
                      ? moment(formatDate(selectMeeting.post_task.startdate, dateFormat))
                      : undefined,
                    selectMeeting?.post_task?.enddate
                      ? moment(formatDate(selectMeeting.post_task.enddate, dateFormat))
                      : undefined,
                  ]}
                  format={dateFormat}
                />
              </div>
              <div className="mb-2 col-lg-3 mt-3">
                <label for="Priority" className="form-label">
                  {translation.priority}
                </label>
                <select
                  className="form-select"
                  aria-label="Select Priority"
                  key="Post_priority"
                  onChange={onInputChange}
                  id="Post_priority"
                  defaultValue={selectMeeting?.post_task?.priority}
                  name="Post_priority"
                >
                  <option selected>{translation.select_priority}</option>
                  <option value="Urgent">Urgent</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
              <div className="mb-2 col-lg-3 mt-3">
                <label for="Status" className="form-label">
                  {translation.status}
                </label>
                <select
                  className="form-select"
                  aria-label=" Select Status"
                  key="Post_status"
                  onChange={onInputChange}
                  defaultValue={selectMeeting?.post_task?.status}
                  name="Post_status"
                >
                  {" "}
                  <option selected>{translation.select_status}</option>
                  <option value="Pending">Pending</option>
                  <option value="Started">Started</option>
                  <option value="Inprogress">Inprogress</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
              <div className="mb-2 col-lg-3 mt-3">
                <label for="Task Assigned To" className="form-label">
                  {translation.task_assigned_to}
                </label>
                <select
                  className="form-select"
                  aria-label=" Select Task Assigned To"
                  key="Post_task_assign_to"
                  onChange={onInputChange}
                  defaultValue={selectMeeting?.post_task?.task_assign_to}
                  name="Post_task_assign_to"
                >
                  {" "}
                  <option selected>{translation.task_assigned_to}</option>
                  {employees.map((e, i) => (
                    <option key={e.employee_id} value={e.employee_id}>
                      {e.first_name + " " + e.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-2 col-lg-3 mt-3">
                <label for="Employee" className="form-label">
                  {translation.employee}
                </label>
                <select
                  className="form-select"
                  aria-label=" Select Employee"
                  key="Post_employee_id"
                  onChange={onInputChange}
                  name="Post_employee_id"
                  defaultValue={selectMeeting?.post_task?.employee_id}
                >
                  {" "}
                  <option selected>{translation.employee}</option>
                  {employees.map((e, i) => (
                    <option key={e.employee_id} value={e.employee_id}>
                      {e.first_name + " " + e.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12  mt-2">
                <label for="Description" className="form-label">
                  {translation.description}
                </label>
                <textarea
                  className="form-control"
                  id="Post_desc"
                  rows="3"
                  placeholder="Describe Here"
                  name="Post_desc"
                  key="Post_desc"
                  defaultValue={selectMeeting?.post_task?.desc}
                  onChange={onInputChange}
                ></textarea>
              </div>
              <div className="col-12  mt-2">
                <label for="Remarks" className="form-label">
                  {translation.remarks}
                </label>
                <textarea
                  className="form-control"
                  id="Post_remarks"
                  rows="3"
                  placeholder="Remarks Here"
                  name="Post_remarks"
                  key="Post_remarks"
                  defaultValue={selectMeeting?.post_task?.remarks}
                  onChange={onInputChange}
                ></textarea>
              </div>
            </Tabs.TabPane>
          </Tabs>
          <div className="col-lg-12 mb-2 d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-danger px-3 py-2 me-2"
              onClick={() => onCancel()}
            >
              {translation.cancel}
            </button>
            <button
              type="button"
              className="btn btn-primary px-3 py-2"
              onClick={() => updateMeetingHandler()}
            >
              {translation.update}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMeeting;
