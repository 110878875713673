import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addActionColumn } from "../../helpers/companyHelpers";
import TableCard from "../TableCard";
import { Modal, Select } from "antd";
import useTranslation from "../customHooks/translations";
import FundForm from "./FundForm";
import EnergyForm from "./EnergyForm";
import { projectProfileAction } from "../actions/projectProfileAction";
import { deleteHandler } from "../../apiParsing/data";
import { apipaths } from "../../services/apiPaths";
import { projectProfileColumn } from "../../Data/Columns/projectProfileColumn";
const { Option } = Select;

const ProjectProfileDataTable = ({ setModal, projectId }) => {
  const { projectProfiles } = useSelector((state) => state.projectProfile);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const translation = useTranslation();

  const [value, setValue] = useState(translation.fund_form);
  const [layout, setLayout] = useState([]);
  const [editData, setEditData] = useState({});

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(projectProfileAction());
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const editHandler = (allData) => {
    setEditData(allData);
    setIsModalVisible(true);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div>
      <Modal
        width="75%"
        title={value}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <select
          value={value}
          onChange={handleChange}
          style={{
            width: 120,
          }}
          className="form-select w-100"
        >
          <option value={translation.fund_form}>{translation.fund_form}</option>
          <option value={translation.energy_form}>
            {translation.energy_form}
          </option>
        </select>

        <>
          {value == translation.fund_form ? (
            <FundForm
              projectId={projectId}
              setIsModalVisible={setIsModalVisible}
              isModalVisible={isModalVisible}
            />
          ) : (
            <EnergyForm />
          )}
        </>
      </Modal>

      <TableCard
        title={translation.profile}
        button={{
          title: translation.add_profile,
          onClick: () => setIsModalVisible(true),
        }}
        columns={addActionColumn(
          {},
          projectProfileColumn,
          (allData) => editHandler(allData),
          (data) =>
            deleteHandler(
              apipaths.deleteTask,
              data?.["task_id"],
              setModal,
              dispatch,
              projectProfileAction
            ),
          translation._language
        )}
        jaCol={addActionColumn(
          {},
          projectProfileColumn,
          (allData) => editHandler(allData),
          (data) =>
            deleteHandler(
              apipaths.deleteTask,
              data?.["task_id"],
              setModal,
              dispatch,
              projectProfileAction
            ),
          translation._language
        )}
        data={projectProfiles}
      />
    </div>
  );
};

export default ProjectProfileDataTable;
