import React from "react";
import { useParams } from "react-router-dom";
import CardContainer from "../Card/cardContainer";
import Heading from "../Heading";
// import { Tabs } from "antd";
import TaskForm from "./taskForm";
// import SubTaskForm from "./subtaskForm";

function TaskFormContainer() {
  const { id } = useParams();
  // const { TabPane } = Tabs;

  return (
    <>
      <div className="main-container">
        <Heading title={`${id} Task`} />
        <div className="content-wrapper px-5">
          <CardContainer hideTotal={true} />
          <div className="content-container">
            <div className="p-3">
              <TaskForm />
              {/* <Tabs defaultActiveKey="1">
                <TabPane tab="Tasks" key="1">
                  <div className="px-2">
                  </div>
                </TabPane>
                <TabPane tab="Sub Task" key="2">
                  <div className="px-2">
                    <SubTaskForm />
                  </div>
                </TabPane>
              </Tabs> */}
            </div>
            <hr />
            <div className="pt-1 pb-4 px-3">{/* form container */}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskFormContainer;
