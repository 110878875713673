import React, { useEffect, useState } from "react";
import "./style.css";
import { Form, Input, Button } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { sendRequest } from "../../services";
import { apipaths } from "../../services/apiPaths";

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  useEffect(() => {
    const hasAuthAccessToken = localStorage.getItem("auth-token");
    if (hasAuthAccessToken) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const loginHandler = async (formdata) => {
    setError(`Login in please wait...`);
    const res = await sendRequest(apipaths.signIn, formdata);
    if (res.success) {
      const {
        data: { token },
      } = res;
      localStorage.setItem("auth-token", token);
      navigate("/dashboard");
    } else setError(res.message);
  };

  return (
    <div className="row mx-auto vh-100 align-items-center justify-content-center">
      <div className="col-lg-7 col-md-7 px-0 vh-100 hide-sm-and-down">
        <img
          className="login-img"
          alt="login-banner"
          src="./images/banner2.png"
        />
      </div>
      <div className="col-lg-5 col-md-5 col-12">
        <div className="login-form-container">
          <div className="text-center my-4">
            <img src="./images/jaim_logo.png" alt="logo" className="img-logo" />
          </div>
          <h3 className="fs-20 text-muted">Login</h3>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={loginHandler}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <p className="login-status text-ff7f27">{error && error}</p>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="login-form-button w-100 py-1"
                >
                  Log in
                </Button>
              )}
            </Form.Item>
          </Form>
          {/* <div className="text-center fs-13">
            <span className="text-999">Forget</span>{" "}
            <Link className="text-666" to={"/forget"}>
            Username / Password?
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
