import {
  formatDateMeet,
  onSuccesshandler,
} from "../helpers/commonHelpers";
import { isCompanyId } from "../helpers/companyHelpers";
import { sendRequest } from "../services";
import { apipaths } from "../services/apiPaths";

export async function addNotehandler(
  formdata,
  setModal,
  dispatch,
  notesListAction,
  companyId
) {
  const formData = new FormData();
  formData.append("title", formdata.title);
  formData.append("date", formdata.date)
  formData.append("company_id", formdata.company_id);
  formData.append("desc", formdata.desc);
  formData.append("document", formdata.document); // Ensure this is a File object
  formData.append("employee_id", formdata.employee_id);
  formData.append("project_id", formdata.project_id);
  formData.append("operation", "add");
  
  if(formdata.desc === undefined){
    formdata.desc = null
  }
  if(formdata.project_id === undefined){
    formdata.project_id = null
  }
  
  const form_data_withId = isCompanyId(companyId, formData);
  const { success, message } = await sendRequest(
    apipaths.addnotes,
    form_data_withId
  );
  if (success) {
    onSuccesshandler(setModal, dispatch, notesListAction, message);
  }
}

export async function updateNoteHandler(
  apiUrl,
  editData,
  id,
  setModal,
  dispatch,
  renderList
) {
  const formdata = editData;
  // delete formdata["date"];
  // formdata["date"] = editData.date;
  formdata["id"] = editData[`${id}`];
  delete formdata[`${id}`];
  formdata["operation"] = "update";
  const { success, message } = await sendRequest(apiUrl, formdata);
  if (success) {
    onSuccesshandler(setModal, dispatch, renderList, message);
  }
}
